@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap");

// @import "rsuite/dist/rsuite.css";
@font-face {
  font-family: "f37";
  src: url("../src/font/incise/f37incisemedium.ttf");
}

@font-face {
  font-family: "bl";
  src: url("../src/font/blarctic/blarctic.otf");
}

@font-face {
  font-family: "hlight";
  src: url("../src/font/helveteca/HelveticaNowDisplay-Light.ttf");
}

@font-face {
  font-family: "hregular";
  src: url("../src/font/helveteca/HelveticaNowDisplay-Regular.ttf");
}

@font-face {
  font-family: "hmedium";
  src: url("../src/font/helveteca/HelveticaNowDisplay-Medium.ttf");
}

@font-face {
  font-family: "hsemibold";
  src: url("../src/font/helveteca/HelveticaNowDisplay-Bold.ttf");
}

@font-face {
  font-family: "hbold";
  src: url("../src/font/helveteca/HelveticaNowDisplay-ExtraBold.ttf");
}

html {
  scroll-behavior: smooth;
}

body {
  background: #e2e9ec;
  height: 100vh;
}

a {
  &:hover {
    background-color: transparent !important;
  }
}

input[type="search"] {
  -webkit-appearance: none;
  border-radius: 0;
}

.row {
  margin: 0;
  padding: 0;
}

.p-0 {
  padding: 0px;
}

.p0 {
  padding: 0px;
}

// All transitions
div,
ul,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
button {
  -webkit-transition: background-color 0.7s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
}

textarea {
  resize: none;
  height: 60px;
  width: 100%;
}

a {
  text-decoration: none !important;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0;
}

.grey {
  color: #888 !important;
}

.ptb20 {
  padding: 20px 0;
}

.ptb60 {
  padding: 60px 0;
}

.cursor-pointer {
  cursor: pointer;
}

.purple {
  color: #6c01c3;
}

.yellow {
  color: #ffc13d;
}

.border-img {
  border-radius: 10px;
}

.common {
  color: #ff0083;
}

.green {
  color: #329879;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.14px;
}

.red {
  color: #f41e5e;
}

.white {
  color: #fff !important;
}

.blue {
  color: #133572;
}

.navbar-toggle {
  background-color: #333;

  .icon-bar {
    background-color: #eee;
  }
}

@media only screen and (max-width: 600px) {
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    outline: none;
    box-shadow: none !important;

    &:focus {
      outline: none;
    }
  }

  .main-footer {
    text-align: center;

    .text-right {
      text-align: center !important;
    }
  }

  h1 {
    font-size: 2.5rem !important;
  }

  .ptb {
    padding: 30px 0;
  }

  .xs-0 {
    padding: 0px;
  }

  .main-mint,
  .main-banner {
    .inner-content {
      .modal-content {
        .for-padding {
          padding: 0 0px !important;
        }
      }
    }
  }

  .main-give {
    .right-side {
      margin-top: 30px !important;

      h5 {
        font-family: "hmedium";
      }

      li {
        margin-right: 2px !important;
      }
    }
  }
}

// 12 pro
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  h1 {
    font-size: 3rem !important;
  }

  .ptb {
    padding: 20px 0;
  }
}

iframe {
  position: unset !important;
  top: 0px !important;
  left: 0px !important;
  width: 0px !important;
  height: 0px !important;
}

button {
  cursor: pointer;
}

@media (max-width: 600px) {
  h2 {
    font-size: 30px !important;
  }

  .col-md-6 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .col-md-8 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .col-md-4 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

button:focus {
  outline: none !important;
}

input:focus {
  outline: none !important;
}

.form-control:focus {
  box-shadow: unset;
}

@media (max-width: 600px) {
  .modal.show .modal-dialog {
    max-width: 100% !important;
  }
}

button {
  cursor: pointer;
}

a {
  cursor: pointer;
}

button,
input,
optgroup,
select,
textarea:focus-visible {
  outline: none !important;
}

button,
input,
optgroup,
select,
textarea:focus {
  outline: none !important;
}

button.close {
  opacity: 1;
}

// input styling scss here..........................................

.material-textfield {
  position: relative;
  width: 100%;
  margin-bottom: 15px;
}

.material-textfield input {
  outline: none;
  transition: 0.1s ease-out;
  border-radius: 4px;
  background: var(--Text-Field-Background, #d1dbdf);
  padding: 20px 18px;
  width: 100%;
  box-shadow: none !important;
  color: #000;
  font-family: "hregular";
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  border: none;

  &:hover {
    &::placeholder {
      color: #000;
      transition: 0.3s;
    }
  }

  &::placeholder {
    color: rgba(2, 23, 29, 0.5);
    font-family: "hregular";
    font-size: 14px;
    font-style: normal;
    line-height: normal;
  }
}

// custom checkbox scss here........................................

.custom-checkbox {
  display: flex !important;
  align-items: center;
  justify-content: space-between;

  a {
    color: #77868b;
    text-align: center;
    font-family: "hmedium";
    font-size: 14px;
    font-style: normal;
    line-height: normal;
  }

  .form-group {
    display: block;
  }

  .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  .form-group label {
    position: relative;
    cursor: pointer;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-family: "hregular";
    line-height: normal;
  }

  .form-group label:before {
    content: "";
    -webkit-appearance: none;
    background-color: #fff;
    border: 2px solid #eee;
    padding: 8px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }

  .form-group input:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    top: 4px;
    left: 7px;
    width: 6px;
    height: 14px;
    border: solid #000;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

.btn-login {
  border-radius: 4px;
  background: #329879;
  display: flex;
  padding: 18px 22px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #e1e9ec;
  font-family: "hregular";
  font-size: 14px;
  font-style: normal;
  line-height: 100%;
  width: 100%;
  border: none;

  &:hover {
    background: #1e7e61;
    transition: 0.3s;
  }

  &:focus {
    background: #1e7e61;
    box-shadow: 0px 0px 10px 8px rgba(50, 152, 121, 0.5);
    transition: 0.3s;
  }
}

.custom-container {
  max-width: 1370px;
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

.logout-btn {
  display: flex;
  padding: 18px 22px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #fc4f52;
  color: rgba(225, 233, 236, 0.91);
  font-family: "hmedium";
  font-size: 14px;
  font-style: normal;
  line-height: 100%;
  height: 42px;
  border: none;
}

.add-leader {
  border-radius: 4px;
  background: #329879;
  display: flex;
  padding: 18px 22px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #e1e9ec;
  font-family: "hregular";
  font-size: 14px;
  font-style: normal;
  line-height: 100%;
  border: none;
  height: 42px;

  &:hover {
    background: #1e7e61;
    transition: 0.3s;
  }

  &:focus {
    background: #1e7e61;
    box-shadow: 0px 0px 10px 8px rgba(50, 152, 121, 0.5);
    transition: 0.3s;
  }
}

// custom-sidebar scss here....................................
.custom-sidebar {
  border-right: none;
  background: #e2e9ec;
  padding: 0px 0;
  max-width: 274px;
  width: 100%;
  height: 100vh;
  overflow: auto;

  .brdrtop {
    position: absolute;
    height: 1px;
    width: 100%;
    top: 104px;
    left: 0;
    right: 0;
    border-top: 1px solid #c2cccf;
  }

  .custom-sidebar-top-menu {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    padding: 49px 25px 20px 18px;
    margin-left: 31px;
    // border-right: 1px solid #C2CCCF;
    border-left: 1px solid #c2cccf;
  }

  ul {
    padding: 36px 0px;
    border-right: 1px solid #c2cccf;
    border-left: 1px solid #c2cccf;
    margin-left: 31px;
    height: 100vh;

    .innerlistpara {
      color: var(--Secondary-Text-Color, #77868b);
      -webkit-text-stroke-width: 0.10014298558235168;
      -webkit-text-stroke-color: #329879;
      font-family: "f37";
      font-size: 12px;
      font-style: normal;
      font-family: "hmedium";
      line-height: 110%;
      text-transform: uppercase;
      border-top: 1px solid #c2cccf;
      padding-top: 24px;
      padding-bottom: 20px;
      padding-left: 30px;
      padding-right: 30px;
    }

    .btnsmains {
      padding: 0px 30px;
    }

    li {
      a {
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        color: #02171d;
        font-family: "f37";
        font-size: 14px;
        font-style: normal;
        line-height: 110%;
        margin-bottom: 24px;
        padding-left: 30px;
        padding-right: 30px;

        /* 14px */
        &.active {
          color: #329879;
          -webkit-text-stroke-width: 0.10014298558235168;
          -webkit-text-stroke-color: #329879;

          svg path {
            stroke: #329879;
            fill: #329879;
            -webkit-text-stroke-width: 0.10014298558235168;
            -webkit-text-stroke-color: #329879;
          }

          svg circle {
            stroke: #329879;
            fill: #329879;
            -webkit-text-stroke-width: 0.10014298558235168;
            -webkit-text-stroke-color: #329879;
          }

          svg rect {
            stroke: #329879;
            fill: #329879;
            -webkit-text-stroke-width: 0.10014298558235168;
            -webkit-text-stroke-color: #329879;
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .custom-sidebar {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
}

.global-modal-scss {
  background: rgba(167, 182, 187, 0.65);
  backdrop-filter: blur(7.5px);

  .modal-content {
    border-radius: 4px;
    background: #e2e9ec;
    padding: 0px;
  }

  .modal-header {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #797977;
    background: #e2e9ec;
    padding: 24px 20px;

    .modal-title {
      color: #02171d;
      font-family: "f37";
      font-size: 20px;
      font-style: normal;
      line-height: 120%;
    }

    .btn-close {
      background: url("../src/assets/close-circle.svg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      opacity: 1;
      box-shadow: none !important;
      width: 17.844px;
      height: 18.603px;
      padding: 0;
      margin: 0;
    }
  }

  .modal-body {
    padding: 25px 20px 30px;
  }

  .twice-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      flex: 0 0 48%;
    }

    .btn-cancel {
      border-radius: 4px;
      background: #d1dbdf;
      display: flex;
      padding: 18px 22px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: #02171d;
      font-family: "hregular";
      font-size: 14px;
      font-style: normal;
      line-height: 100%;
      width: 100%;
      border: none;
    }

    .btn-del {
      border-radius: 4px;
      background: #fe0000;
      display: flex;
      padding: 18px 22px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: #fff;
      font-family: "hregular";
      font-size: 14px;
      font-style: normal;
      line-height: 100%;
      width: 100%;
      border: none;
    }

    .btn-add {
      border-radius: 4px;
      background: #329879;
      display: flex;
      padding: 18px 22px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: #e1e9ec;
      font-family: "hregular";
      font-size: 14px;
      font-style: normal;
      line-height: 100%;
      width: 100%;
      border: none;

      &:hover {
        background: #1e7e61;
        transition: 0.3s;
      }

      &:focus {
        background: #1e7e61;
        box-shadow: 0px 0px 10px 8px rgba(50, 152, 121, 0.5);
        transition: 0.3s;
      }
    }
  }
}

@media (max-width: 991px) {
  .custom-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    max-width: 100%;
    height: 100vh;
    z-index: 999999;
    padding-bottom: 10px;
  }

  .custom-sidebar-top-menu {
    padding: 22px 20px !important;
    margin: 0 !important;
    border: none !important;
    border-bottom: 1px solid #c1cbce !important;
    backdrop-filter: blur(15px);
  }

  .custom-sidebar ul {
    margin-left: 0;
  }

  .custom-sidebar .btnsmains {
    padding-bottom: 10px !important;
  }

  .custom-sidebar ul {
    padding: 0 !important;
  }

  .custom-sidebar .brdrtop {
    display: none !important;
  }

  .custom-sidebar ul li a {
    padding: 10px 20px !important;
    margin: 0 !important;
  }

  .custom-sidebar ul .btnsmains {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 15px;
    margin-top: 40px;
    padding: 0px 20px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.btn-back {
  display: flex;
  height: 36px;
  padding: 11px 14px;
  align-items: center;
  gap: 12px;
  border-radius: 4px;
  background: #329879;
  color: #fff !important;
  text-align: center;
  font-family: "hregular";
  border: none;
  font-size: 12px;
  font-style: normal;
  line-height: 100%;
  box-shadow: none !important;
}

.guvxgyvxvtxx {
  margin-bottom: 40px;
}

// .footer-content .right-f .page-link:hover{
//   background: #fff !important;
//   border-radius: 0 !important;
//   color: black !important;
//   svg path{
//     stroke: #000;
//   }
// }

// .footer-content .right-f .page-link:focus, .footer-content .right-f .page-link:hover{
//   background: #fff !important;
//   border-radius: 0 !important;
//   color: black !important;
//   svg path{
//     stroke: #000;
//   }
// }

// .footer-content .right-f .page-link:focus, .footer-content .right-f .page-link:hover{
//   background: #fff !important;
//   border-radius: 0 !important;
//   color: black !important;
//   svg path{
//     stroke: #000;
//   }
// }

// .footer-content .right-f .page-link:focus, .footer-content .right-f .page-link:hover{
//   background: #fff !important;
//   border-radius: 0 !important;
//   color: black !important;
//   svg path{
//     stroke: #000;
//   }
// }

// .datatable .table-responsive .footer-content .right-f .page-link:hover{
//   background: #fff !important;
//   border-radius: 0 !important;
//   color: black !important;
//   svg path{
//     stroke: #000;
//   }
// }
.asdhgadjasfhjaskf {
  max-width: 77px;
  // text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.sdadasdhtdhfhadsgaegegag {
  display: flex;
  justify-content: start;
  align-items: center;
}

.asdasdasdasd {
  padding-left: 6px !important;
}

.shgdvyufgqwiufaksufhasig {
  flex-wrap: wrap !important;
}

// .rs-picker-toggle .rs-picker-toggle-placeholder{
//   display: none !important;
// }

.custom-option-field {
  position: relative;

  input {
    border-radius: 4px;
    border: 1.5px solid #d0dee4;
    background: #d1dbdf;
    width: 100%;
    height: 34px;
    padding: 12px 15px;
    padding-right: 40px;
    color: #000;
    font-family: "hregular";
    font-size: 14px;
    font-style: normal;
    line-height: 100%;

    &::placeholder {
      color: #77868b;
      font-family: "hregular";
      font-size: 14px;
      font-style: normal;
      line-height: 100%;
    }
  }

  .search-icon {
    position: absolute;
    top: 7px;
    right: 15px;
  }

  .search-icon-btn {
    position: absolute;
    top: 9px;
    right: 8px;
    border: 1px solid #fff;
    background: #000;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 200;
    line-height: 100%;
    text-transform: uppercase;
    padding: 9px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 5px;
    gap: 5px;
    box-shadow: none !important;
    // box-shadow: 4px 4px 0px 0px #FFF;
  }
}

// custom checkbox style here...........................

.custom-checkbox {
  display: block;
}

.custom-checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.custom-checkbox label {
  position: relative;
  cursor: pointer;
}

.ysvtvstyvtiivtyvs {
  opacity: 0 !important;
  width: 0 !important;
  height: 0 !important;
  visibility: hidden !important;

  td {
    opacity: 0 !important;
    width: 0 !important;
    height: 0 !important;
    visibility: hidden !important;
    padding: 0 !important;
  }
}

.custom-checkbox label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #329879;
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.custom-checkbox input:checked + label:before {
  border: 2px solid #329879;
}

.custom-checkbox input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #329879;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.common-text-allowed {
  color: #28e664 !important;
}

.common-text-pending {
  color: #ff8300 !important;
}

.reset-btn-global {
  border: 1px solid #fff;
  background: #000;
  padding: 16px;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-family: "hregular";
  line-height: 100%;
  max-width: 100px;
  width: 100%;
  box-shadow: none !important;
  padding: 16px !important;
  height: 46px;
}

.parent-twice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  max-width: 574px;
  width: 100%;

  .custom-option-field {
    width: 100%;
  }
}

.se-custom-flex-gap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

@media (max-width: 991px) {
  .reset-btn-global {
    max-width: 100%;
  }

  .parent-twice {
    flex-direction: column;
    max-width: 100%;
  }

  .displayblockinmobile {
    display: block !important;
  }
}

input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.parent-twice-custom {
  .set-custom-calendar-div {
    position: absolute;
    top: 0;
    // left: 0;
    left: -77px;
  }
}

@media (max-width: 600px) {
  .parent-twice-custom .set-custom-calendar-div {
    left: 0;
  }
}

.set-custom-calendar-div {
  position: absolute;
  top: 0;
  left: 0;
}

.option-field .calendar-icon {
  position: absolute;
  top: 50px !important;
  right: 20px;
  cursor: pointer;
  pointer-events: none;
}

/* Remove this container when use*/
.component-title {
  width: 100%;
  position: absolute;
  z-index: 999;
  top: 30px;
  left: 0;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  font-family: "hsemibold";
  color: #888;
  text-align: center;
}

.parent-toggle-switch-custom {
  /* The switch - the box around the slider */
  .custom-toggle {
    width: 51px;
    height: 31px;
    position: relative;
  }

  /* Hide default HTML checkbox */
  .checkbox {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
  }

  .redddd {
    background: #fe0000 !important;
  }

  .switch {
    width: 100%;
    height: 100%;
    display: block;
    background-color: #d1dbdf;
    border-radius: 16px;
    cursor: pointer;
    transition: all 0.2s ease-out;
  }

  /* The slider */
  .slider {
    width: 27px;
    height: 27px;
    position: absolute;
    left: calc(50% - 27px / 2 - 10px);
    top: calc(50% - 27px / 2);
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
    transition: all 0.2s ease-out;
    cursor: pointer;
  }

  .checkbox:checked + .switch {
    background-color: #28e664;
  }

  .checkbox:checked + .switch .slider {
    left: calc(50% - 27px / 2 + 10px);
    top: calc(50% - 27px / 2);
  }
}

.multiselect-custom {
  margin-bottom: 40px;
  position: relative;

  .custom-label {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #fff;
    padding: 5px;
    transform: translate(15px, -15px);
    pointer-events: none;
  }

  .searchWrapper {
    border: 1px solid #000;
    background: #fff;
    border-radius: 0;
    min-height: 60px;
    padding: 15px 18px;
  }

  .multiSelectContainer input {
    margin-top: 0;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-family: "hsemibold";
    line-height: 117%;

    &::placeholder {
      color: #343434;
    }
  }

  .chip {
    background: #fff;
    border-radius: 0;
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-family: "hmedium";
    line-height: 120%;
    gap: 5px;
  }

  .optionListContainer {
    border: 1px solid #000;
    background: #fff;
    box-shadow: 4px 4px 0px 0px #fff;
  }

  .multiSelectContainer ul {
    border-radius: 0;
    border: none;
  }

  .multiSelectContainer li {
    border-bottom: 1px solid #f3f3f3;
    background-color: transparent;
    padding: 12px 15px;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-family: "hregular";
    line-height: normal;
  }

  .highlightOption {
    background: transparent !important;

    &:hover {
      background: transparent !important;
    }
  }

  .multiSelectContainer li:hover {
    background: transparent !important;
  }
}

.institute-accord {
  background-color: transparent;
  border: none;
  border-radius: 0;
  box-shadow: none;
  padding: 0;

  .accordion-item {
    background-color: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
    padding: 0;

    .accordion-button {
      background-color: transparent;
      border: none;
      border-radius: 0;
      box-shadow: none;
      padding: 0;

      a {
        width: 100%;
      }

      &::after {
        display: none;
      }

      &[aria-expanded="true"] {
        a {
          .arrow {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .accordion-body {
    padding: 0;
    padding-left: 30px;
  }
}

.modal-width {
  .modal-dialog {
    max-width: 750px;
    width: 100%;
  }
}

@media (max-width: 750px) {
  .modal-width {
    .modal-dialog {
      .twice-inputssss {
        width: 100%;
        justify-content: flex-start;

        .option-field {
          width: 100%;
          flex: unset;
        }
      }
    }
  }
}

.checkboxmain {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  .checkboxpara {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-family: "hregular";
    line-height: normal;
  }

  p.checkboxparafst {
    font-style: normal;
    font-family: "hmedium";
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.1px;
    color: #ffffff;
    padding-left: 10px;
  }

  .checkbox {
    position: relative;
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
  }

  .checkbox input {
    position: absolute;
    width: 0;
    left: 50px;
    height: 0;
    opacity: 0;
    cursor: pointer;
  }

  .checkbox .checkmark {
    position: relative;
    display: block;
    top: 0;
    left: 0;
    width: 19px;
    height: 19px;
    border: 1.5px solid #329879;
    transition: all 0.2s ease;
  }

  .checkbox input:checked ~ .checkmark {
    background: #e2e9ec;
    border: 1.5px solid #329879;
  }

  .checkbox .checkmark::after {
    position: absolute;
    display: block;
    content: "";
    left: 50%;
    top: 40%;
    width: 5.5px;
    height: 12px;
    border: solid #329879;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg);
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    -moz-transform: translate(-50%, -50%) rotate(45deg);
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    opacity: 0;
    transition: all 0.2s ease;
  }

  .checkbox input:checked ~ .checkmark::after {
    opacity: 1;
    transition: all 0.2s ease;
  }
}

.forflex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.walletaddrssmodal {
  .modal-dialog {
    .modal-content {
      // border-radius: 0px !important;

      .modal-body {
        .ssuuccessmain {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding-top: 40px;

          .btn-okay {
            border-radius: 15px;
            background: #53ffea;
            box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.2) inset;
            display: flex;
            padding: 18px 25px;
            justify-content: center;
            align-items: center;
            color: #000;
            text-align: center;
            width: 100%;
            font-size: 14px;
            font-style: normal;
            font-family: "hsemibold";
            line-height: 100%;
            /* 14px */
            text-transform: uppercase;
          }
        }

        .paraconfirm {
          color: #000;
          margin: 40px 0px;
          text-align: center;
          font-size: 22px;
          font-style: normal;
          font-family: "hregular";
          line-height: 110%;
        }

        .walletaddrssmain {
          display: flex;
          padding: 15px 20px;
          flex-direction: column;
          align-items: flex-start;
          background: #fff;
          margin: 30px 0px;

          .walletaddrsspara {
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-family: "hregular";
            line-height: 120%;
            opacity: 0.6;
            margin-bottom: 7px;
          }

          .walletaddrsshead {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-family: "hsemibold";
            line-height: 120%;
          }
        }
      }
    }
  }
}

.set-new-for-closed {
  .modal-dialog {
    max-width: 528px;
  }

  .modal-header {
    border: none;
    justify-content: center;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-family: "hsemibold";
    line-height: 140%;
    border: none;
    background: transparent;
    padding: 15px;
  }

  .modal-content {
    border-radius: 12px;
    background: #fff;
    padding: 20px;
  }

  .modal-body {
    padding: 0;
  }

  p {
    color: #000;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-family: "hregular";
    line-height: 140%;
    margin: 35px 0;
  }

  a {
    border-radius: 15px;
    background: #53ffea;
    box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.2) inset;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #000;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-family: "hsemibold";
    line-height: 100%;
    text-transform: uppercase;
    width: 100%;
  }
}

.ifroundselect {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  margin-top: 10px;

  .maintext {
    border-radius: 4px;
    background: var(--Light-Mode-Primary-Text-Color, #02171d);
    display: flex;
    height: 30px;
    padding: 15px 10px;
    align-items: center;
    gap: 5px;

    h6 {
      margin: 0 !important;
      padding: 0 !important;
      color: #fff !important;
      font-family: "hmedium";
      font-size: 12px !important;
      font-style: normal;
      line-height: 120%;
    }
  }
}

.mycheckbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  .clear {
    clear: both;
  }

  .checkBox {
    display: block;
    cursor: pointer;
    width: 20px;
    height: 20px;
    border: 3px solid rgba(255, 255, 255, 0);
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 0px 0px 2px #329879;
  }

  .checkBox div {
    width: 60px;
    height: 60px;
    background-color: #329879;
    top: -52px;
    left: -52px;
    position: absolute;
    transform: rotateZ(45deg);
    z-index: 100;
  }

  .checkBox input[type="checkbox"]:checked + div {
    left: -10px;
    top: -10px;
  }

  .checkBox input[type="checkbox"] {
    position: absolute;
    left: 50px;
    visibility: hidden;
  }

  .transition {
    transition: 300ms ease;
  }

  .checkboxpara {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-family: "hregular";
    line-height: normal;
  }
}

.addtoken {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 15px;
}

.mylinkkk {
  border-bottom: none !important;
  padding: 0px !important;
  font-size: unset !important;
}

.cyberpunk-checkbox {
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #329879;
  border-radius: 2px;
  background-color: transparent;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.cyberpunk-checkbox:before {
  content: "";
  background-color: #329879;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 12px;
  height: 12px;
  border-radius: 2px;
  transition: all 0.3s ease-in-out;
}

.cyberpunk-checkbox:checked:before {
  transform: translate(-50%, -50%) scale(1);
}

.cyberpunk-checkbox-label {
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
}

.newbtnsets {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 15px;

  .dropdown {
    // max-width: 170px;
    // width: 100%;

    .dropdown-toggle {
      border-radius: 4px;
      background: #d1dbdf;
      color: #02171d;
      font-family: "hmedium";
      border: none;
      outline: none;
      font-size: 12px;
      font-style: normal;
      line-height: 120%;
      width: 100%;
      padding: 8px 12px;
      display: flex;
      justify-content: center;
      align-items: center;

      .se-custom-flex-gap {
        justify-content: space-between;
        width: 100%;
      }

      .projectmain {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;

        .projectimg {
          width: 30px;
        }
      }

      &::after {
        display: none;
      }
    }

    .dropdown-menu {
      border-radius: 4px;
      background: #d1dbdf;
      border: 1px solid #c1cbce;
      padding: 0;
      overflow-y: auto;
      max-height: 400px;

      a {
        padding: 12px 15px;
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-family: "hmedium";
        line-height: normal;
        border-bottom: 1px solid #c1cbce;
        // &:last-child{
        //     border-bottom: 1px solid transparent;
        // }
        background: transparent;

        .projectmain {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 8px;

          .projectimg {
            width: 30px;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .rdrDays {
    gap: 11px !important;
  }
}

.leftshade {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.midshade {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: -1;
}

@media (max-width: 1024px) {
  .leftshadembl {
    display: block !important;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .midshadembl {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }

  .leftshade {
    display: none !important;
  }

  .midshade {
    display: none !important;
  }
}

.selfhead {
  color: var(--Secondary-Text-Color, #77868b);
  -webkit-text-stroke-width: 0.10014298558235168;
  -webkit-text-stroke-color: #329879;
  font-family: "f37";
  font-size: 12px;
  font-style: normal;
  line-height: 110%;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.downloadcsvcustombutton {
  border-radius: 4px;
  background: #d1dbdf;
  padding: 8px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: #02171d;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  white-space: nowrap;
  &:hover {
    background: #d1dbdf !important;
  }
}

@media (max-width: 600px) {
  .downloadcsvcustombutton {
    width: 100%;
  }
  .alltransaction .main-heading .rightside-content .whitnewbtn {
    justify-content: center;
  }
  .custom-sidebar {
    padding-bottom: 150px !important;
    height: 100% !important;
  }
}

.foroverflowvisibleclasss {
  overflow: visible !important;
}
