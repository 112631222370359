.allagents {
  padding: 60px 0;

  .main-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h6 {
      color: var(--Primary-Text-Color, #02171d);
      font-family: "f37";
      font-size: 30px;
      font-style: normal;
      line-height: 24px;
      letter-spacing: 0.6px;
      text-transform: uppercase;
    }

    button {
      border-radius: 4px;
      background: #d1dbdf;
      display: flex;
      height: 34px;
      padding: 8px 12px;
      align-items: center;
      gap: 12px;
      color: #02171d;
      font-family: "hmedium";
      font-size: 12px;
      font-style: normal;
      line-height: 120%;
      border: none;
      box-shadow: none !important;
    }

    .rightside-content {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;

      .whitnewbtn {
        border-radius: 4px;
        background: #d1dbdf;
        display: flex;
        height: 34px;
        padding: 8px 12px;
        align-items: center;
        gap: 12px;
        color: #02171d;
        font-family: "hmedium";
        font-size: 12px;
        font-style: normal;
        line-height: 120%;
        border: none;
        box-shadow: none !important;
      }

      .custom-option-field {
        width: 100%;

        .positionset {
          position: absolute;
          top: 0;
          left: 0;

          .btn {
            border-top-right-radius: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 7px;
            border-bottom-right-radius: 0px;
            width: unset !important;
            border-right: 1px solid #d0dee4 !important;

            &:hover {
              background: transparent !important;
              color: #02171d;
            }
          }
        }
      }

      .dropdown {
        max-width: 110px;
        width: 100%;

        .dropdown-toggle {
          padding: 15px;
          width: 100%;
          box-shadow: none;
          height: 34px;

          &[aria-expanded="true"] {
            box-shadow: none;
          }

          &::after {
            display: none;
          }
        }

        .dropdown-menu {
          border-radius: 4px;
          background: #d1dbdf;
          border: 1px solid #c1cbce;
          padding: 0;
          box-shadow: none !important;
          overflow: hidden;

          a {
            padding: 12px 15px;
            color: #02171d;
            font-family: "hregular";
            font-size: 12px;
            font-style: normal;
            line-height: 100%;
            border-bottom: 1px solid #c1cbce;
            // &:last-child{
            //     border-bottom: 1px solid transparent;
            // }
            background: transparent;
          }
        }
      }
    }
  }

  .multi-items {
    max-width: 938px;
    width: 100%;

    .set-twice-text {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 15px;
      margin-bottom: 15px;

      h6 {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-family: "hregular";
        line-height: normal;
      }
    }
  }
}

.viewdetail {
  padding: 60px 0;

  .main-heading111 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    button {
      border: 1px solid #fff;
      background: #000;
      box-shadow: 4px 4px 0px 0px #fff;
      padding: 13px 45px;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-family: "hregular";
      line-height: 100%;
      /* 16px */
    }

    .rightside-content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;

      .custom-option-field {
        width: 91%;

        input {
          height: 40px;
        }

        .search-icon {
          top: 11px;
        }
      }

      .dropdown {
        max-width: 180px;
        width: 100%;

        .dropdown-toggle {
          padding: 17px;
          width: 100%;
          border-radius: 4px;
          background: #d1dbdf;
          box-shadow: none;
          height: 40px;
          color: #000 !important;
          border: none;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &::after {
            display: none;
          }
        }

        .dropdown-menu {
          border-radius: 4px;
          background: #d1dbdf;
          border: 1px solid #c1cbce;
          width: 100%;
          overflow: hidden;
          padding: 0;

          a {
            padding: 12px 15px;
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-family: "hregular";
            line-height: normal;
            border-bottom: 1px solid #c1cbce;
            // &:last-child{
            //     border-bottom: 1px solid transparent;
            // }
          }
        }
      }
    }
  }

  .main-heading2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    button {
      border: 1px solid #fff;
      background: #000;
      box-shadow: 4px 4px 0px 0px #fff;
      padding: 13px 45px;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-family: "hregular";
      line-height: 100%;
      /* 16px */
    }

    .rightside-content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .custom-option-field {
        max-width: 950px;
        width: 100%;

        input {
          height: 40px;
        }

        .search-icon {
          top: 11px;
        }
      }

      .dropdown {
        max-width: 180px;
        width: 100%;

        .dropdown-toggle {
          padding: 8px 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          box-shadow: none;
          height: 40px;
          border-radius: 4px;
          background: #d1dbdf;
          border: none;
          color: #02171d;
          font-family: "hmedium";
          font-size: 14px;
          font-style: normal;
          line-height: 120%;

          .se-custom-flex-gap {
            justify-content: space-between;
            width: 100%;
          }

          &[aria-expanded="true"] {
            box-shadow: none;
          }

          &::after {
            display: none;
          }
        }

        .dropdown-menu {
          border-radius: 4px;
          background: #d1dbdf;
          border: 1px solid #c1cbce;
          width: 100%;
          padding: 0;
          overflow: hidden;

          a {
            padding: 12px 15px;
            color: #02171d;
            font-family: "hmedium";
            font-size: 14px;
            font-style: normal;
            line-height: 120%;
            border-bottom: 1px solid #c1cbce;
            // &:last-child{
            //     border-bottom: 1px solid transparent;
            // }
          }

          .custom-option-field {
            input {
              border: none;
            }
          }
        }
      }
    }
  }

  .main-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn-back {
      display: flex;
      height: 36px;
      padding: 11px 14px;
      align-items: center;
      gap: 12px;
      border-radius: 4px;
      background: #329879;
      color: #fff !important;
      text-align: center;
      font-family: "hregular";
      border: none;
      font-size: 12px;
      font-style: normal;
      line-height: 100%;
      box-shadow: none !important;
    }

    .twice-btn {
      display: flex;
      align-items: center;
      gap: 12px;

      .dropdown {
        max-width: 170px;
        width: 100%;

        .dropdown-toggle {
          padding: 15px;
          width: 100%;
          height: 46px;
          border-radius: 12px;
          border: 1px solid #e6e6e6 !important;
          background: #fff;
          box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 14px;
          color: #000 !important;
          font-size: 16px;
          font-style: normal;
          font-family: "hregular";
          line-height: 100%;

          .projectmain {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;

            .projectimg {
              width: 30px;
            }
          }

          &::after {
            display: none;
          }
        }

        .dropdown-menu {
          border-radius: 12px;
          border: 1px solid #e6e6e6;
          background: #fff;
          box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;

          a {
            padding: 12px 15px;
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-family: "hregular";
            line-height: normal;
            border-bottom: 1px solid #e6e6e6;
            // &:last-child{
            //     border-bottom: 1px solid transparent;
            // }
            background: transparent;

            .projectmain {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 8px;

              .projectimg {
                width: 30px;
              }
            }
          }
        }
      }

      .btn-edit {
        border: 1px solid #000;
        background: #fff;
        padding: 13px 30px;
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-family: "hsemibold";
        line-height: 100%;
        /* 14px */
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .btn-del {
        border-radius: 6px !important;
        background: #fc4f52;
        border: none;
        padding: 13px 30px;
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-family: "hmedium";
        line-height: 100%;
        border-radius: 30px;
        /* 14px */
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .btn-del-grn {
        border-radius: 6px !important;
        background: #28e664;
        border: none;
        padding: 13px 30px;
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-family: "hmedium";
        line-height: 100%;
        border-radius: 30px;
        /* 14px */
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }

  .newheadingss {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .inner-heading {
    margin: 20px 0;

    h4 {
      color: var(--Primary-Text-Color, #02171d);
      font-family: "f37";
      font-size: 30px;
      font-style: normal;
      line-height: 24px;
      letter-spacing: 0.6px;
      text-transform: uppercase;
    }

    .newspan {
      border-radius: 10px;
      border: 1px solid #e79c0d;
      background: rgba(234, 180, 76, 0.1);
      display: flex;
      padding: 8px 10px;
      align-items: center;
      gap: 6px;
      color: #e79c0d;
      font-size: 22px;
      font-style: normal;
      font-family: "hsemibold";
      line-height: 120%;
      letter-spacing: -0.22px;
      text-transform: uppercase;
      max-width: fit-content;
    }
  }

  .bottom-cards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;

    .single-card {
      border-radius: 4px;
      background: #ecf5f9;
      box-shadow: none;
      min-height: 310px;
      flex: 0 0 19.4%;
      padding: 20px;
      position: relative;

      .gems-logo-img {
        width: 35px;
        height: 35px;
      }

      p {
        margin-top: 15px;
        margin-bottom: 10px;
        color: #77868b;
        font-family: "hregular";
        font-size: 14px;
        font-style: normal;
        line-height: 120%;
      }

      h6 {
        color: #021c24;
        font-family: "f37";
        font-size: 18px;
        font-style: normal;
        line-height: 120%;
        letter-spacing: -0.18px;
      }

      .bottom-text {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        margin-top: 10px;
        flex-wrap: wrap;

        .uni-text {
          opacity: 1;
          display: flex;
          align-items: center;
          gap: 5px;
          color: #021c24;
          font-family: "hregular";
          font-size: 14px;
          font-style: normal;
          line-height: 120%;
          letter-spacing: -0.14px;
          margin: 0;

          span {
            background-color: #c1cbce;
            border-radius: 4px;
            width: 3px;
            height: 14px;
            border-radius: 4px;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }

  .parent-new-card {
    border-radius: 4px;
    background: #ecf5f9;
    box-shadow: none;
    margin: 20px 0;
    padding: 30px;

    .main-headingg {
      h6 {
        color: #02171d;
        font-size: 22px;
        font-style: normal;
        font-family: "f37";
        line-height: 110%;
        margin-bottom: 30px;
      }
    }
  }

  .bottom-cards1 {
    padding-right: 190px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 34px;

    .inner-text {
      flex: 0 0 240px;

      p {
        color: #02171d;
        font-size: 14px;
        font-style: normal;
        font-family: "hregular";
        line-height: 120%;
        /* 16.8px */
        opacity: 0.6;
        margin-bottom: 12px;
      }

      h6 {
        color: #02171d;
        font-size: 16px;
        font-style: normal;
        font-family: "f37";
        line-height: 120%;
        /* 19.2px */
      }
    }
  }

  .bottom-cards2 {
    margin-top: 30px;

    h4 {
      color: #02171d;
      font-size: 22px;
      font-style: normal;
      font-family: "f37";
      line-height: 110%;
      /* 24.2px */
      margin-bottom: 30px;
    }

    .parent-card {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 34px 0;

      .inner-text {
        flex: 0 0 420px;
        min-height: 50px;
        max-height: 200px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          display: none;
        }

        p {
          color: #02171d;
          font-size: 14px;
          font-style: normal;
          font-family: "hregular";
          line-height: 120%;
          /* 16.8px */
          opacity: 0.6;
          margin-bottom: 12px;
        }

        .style-text {
          color: #02171d;
          font-size: 16px;
          font-style: normal;
          font-family: "f37";
          line-height: 120%;
          margin-bottom: 5px;
          text-decoration: underline;
          /* 19.2px */
        }

        h6 {
          color: #02171d;
          font-size: 16px;
          font-style: normal;
          font-family: "f37";
          line-height: 120%;
          margin-bottom: 5px;
          /* 19.2px */
        }
      }
    }
  }

  .bottom-cards3 {
    .parent-card .inner-text {
      flex: 0 0 310px !important;
    }
  }

  .viewdetail-tabs {
    max-width: fit-content;
    width: 100%;
    justify-content: space-between;
    border-radius: 4px;
    background: #d9e2e5 !important;
    border: none !important;
    padding: 4px 2px;
    gap: 5px;

    .nav-item {
      flex: 1;

      .nav-link {
        color: #77868b;
        font-family: "f37";
        font-size: 14px;
        font-style: normal;
        line-height: 100%;
        text-transform: uppercase;
        border: none;
        display: flex;
        padding: 8px 20px;
        justify-content: center;
        align-items: center;
        width: 100%;
        border-radius: 4px;
        white-space: nowrap;
      }
    }

    .nav-item.show .nav-link,
    .nav-link.active {
      background: #c1ece3 !important;
      color: #329879 !important;
    }
  }

  .valuemaindiv {
    position: absolute;
    right: 20px;
    bottom: 20px;

    .dropdown {
      .btn {
        padding: 0px;
        margin: 0px;
        background: transparent;
        border: none;
        outline: none;

        &::after {
          display: none !important;
        }
      }

      .dropdown-menu {
        padding: 0px;
        margin: 0px;
        border-radius: 4px;
        background: #ecf5f9;
        box-shadow: none;
        border: 1px solid #c1cbce;
        width: 208px;

        .teamsdrops {
          .innerteamdrop {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px 18px;
            border-bottom: 1px solid #c1cbce;

            .teamdroppara {
              color: #77868b;
              font-family: "hregular";
              font-size: 14px;
              font-style: normal;
              line-height: 120%;
              margin: 0;
            }

            .teamdrophead {
              color: var(--Black, #000);
              font-family: "hregular";
              font-size: 14px;
              font-style: normal;
              line-height: 120%;
            }
          }
        }
      }
    }

    .valuemain {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;

      .valueline {
        border-radius: 4px;
        background: #dfdfdf;
        width: 3px;
        height: 14px;
        display: block;
      }

      .valuepara {
        color: var(--Black, #000);
        font-size: 14px;
        font-style: normal;
        font-family: "hregular";
        line-height: 120%;
        letter-spacing: -0.14px;

        .darktext {
          color: var(--Black, #000);
          font-size: 14px;
          font-style: normal;
          font-family: "hmedium";
          line-height: 120%;
          letter-spacing: -0.14px;
          margin-right: 5px;
        }
      }
    }
  }
}

.delete-modal {
  .delete-modal-div {
    padding: 45px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h6 {
      color: #000;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-family: "hsemibold";
      line-height: 110%;
      /* 22px */
      margin-top: 18px;
      margin-bottom: 12px;
    }

    p {
      color: #000;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-family: "hregular";
      line-height: 110%;
      /* 17.6px */
    }
  }

  .btn-confirm {
    border-radius: 4px;
    background: #329879;
    display: flex;
    padding: 18px 22px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #e2e9ec;
    font-family: "hregular";
    font-size: 14px;
    font-style: normal;
    line-height: 100%;
    width: 100%;
    border: none;
  }
}

.add-mega-modal {
  .add-mega-div {
    padding: 20px 0px;

    h6 {
      color: #000;
      text-align: center;
      font-size: 22px;
      font-style: normal;
      font-family: "hregular";
      line-height: 110%;
      /* 24.2px */
      padding-bottom: 40px;
    }
  }

  .option-field {
    margin-bottom: 35px;

    .dropdown {
      .dropdown-toggle {
        outline: none;
        transition: 0.1s ease-out;
        border-radius: 4px;
        background: #d1dbdf;
        background: var(--Text-Field-Background, #d1dbdf);
        padding: 20px 18px;
        width: 100%;
        box-shadow: none !important;
        color: #000;
        font-family: "hregular";
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        border: none;
      }

      .dropdown-menu {
        border: 1px solid #c1cbce;
        background: #d1dbdf;
        padding: 0;
        width: 100%;
        border-radius: 4px;
        overflow: hidden;

        a {
          border-bottom: 1px solid #c1cbce;
          background: #d1dbdf;
          padding: 14px 15px;
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-family: "hregular";
          line-height: normal;
        }
      }
    }
  }
}

.allagents .main-heading .rightside-content .dropdown .gycytctdtrctrc {
  max-height: 400px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 991px) {
  .veiwdetaileinnerpage .view-btn {
    display: none !important;
  }

  .allagents .main-heading {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
  }

  .allagents .main-heading button {
    width: 100%;
  }

  // .viewdetail .main-heading .twice-btn {
  //   display: none;
  // }

  .viewdetail .main-heading .dropdown {
    display: block !important;
  }

  .viewdetail .dropdown {
    .dropdown-toggle {
      background-color: transparent;
      // border: none;

      &::after {
        display: none;
      }
    }

    .dropdown-menu {
      border: 1px solid #fff;
      background: #000;
      box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.05);
      padding: 0;

      a {
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-family: "hregular";
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        padding: 14px 15px;
      }
    }
  }

  .viewdetail .inner-heading h4 {
    font-size: 32px;
  }

  .viewdetail .inner-heading h4 span {
    font-size: 14px;
  }

  .viewdetail .bottom-cards .single-card {
    flex: 0 0 48.3% !important;
    height: 250px;
    padding: 15px;
  }

  .viewdetail .bottom-cards .single-card p {
    font-size: 12px;
  }

  .viewdetail .bottom-cards .single-card h6 {
    font-size: 16px;
  }

  .viewdetail .bottom-cards .single-card .bottom-text {
    gap: 15px;
    flex-wrap: wrap;
  }

  .viewdetail .bottom-cards .single-card .bottom-text .uni-text {
    font-size: 12px;
  }

  .viewdetail .bottom-cards1 {
    padding: 0;
    gap: 40px 0;
    justify-content: space-between;
  }

  .viewdetail .parent-new-card {
    padding: 25px;
  }

  .viewdetail .bottom-cards1 .inner-text {
    flex: 0 0 48%;
  }

  .viewdetail .bottom-cards1 .inner-text h6 {
    font-size: 14px;
    // max-width: 120px;
    width: 100%;
    // overflow: hidden;
    // text-overflow: ellipsis;
    word-break: break-all;
  }

  .allagents .main-heading .rightside-content {
    flex-wrap: wrap;
    gap: 15px;
  }

  .allagents .main-heading .rightside-content .dropdown {
    max-width: 100%;
    flex: 0 0 47%;
  }

  .viewdetail .main-heading111 .rightside-content {
    flex-direction: column;
    gap: 15px;
  }

  .viewdetail .main-heading111 .rightside-content .custom-option-field {
    width: 100%;
  }

  .viewdetail .main-heading111 .rightside-content .dropdown {
    max-width: 100%;
  }

  .viewdetail .main-heading111 .rightside-content .dropdown .dropdown-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .viewdetail .main-heading2 .rightside-content {
    flex-wrap: wrap;
    gap: 15px;
  }

  .viewdetail .main-heading2 .rightside-content .dropdown {
    max-width: 100%;
    flex: 0 0 47%;
  }

  .viewdetail .bottom-cards2 .parent-card .inner-text {
    flex: 0 0 46%;
  }
}

@media (max-width: 600px) {
  .viewdetail .bottom-cards .single-card h6 {
    word-break: break-all !important;
  }

  .datatable .mygreybtn {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
  }

  .viewdetail .main-heading {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;
  }

  .viewdetail .main-heading .twice-btn {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    width: 100%;
  }

  .viewdetail .main-heading .twice-btn button {
    width: 100%;
    justify-content: center;
  }
}

@media (max-width: 376px) {
  .viewdetail .bottom-cards .single-card h6 {
    font-size: 12px !important;
  }

  .viewdetail .viewdetail-tabs .nav-item .nav-link {
    font-size: 14px;
  }
}

@media (max-width: 1300px) {
  .viewdetail .main-heading2 .rightside-content .custom-option-field {
    max-width: 1000px;
  }
}

.set-custom-scrollbar {
  max-height: 400px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.gvcvtdvdvtdcvdtc .custom-option-field {
  max-width: 90% !important;
}

.fxrxxrxxrexxr .custom-option-field {
  width: 100% !important;
}

@media (max-width: 991px) {
  .fxrxxrxxrexxr .custom-option-field {
    width: 100% !important;
  }

  .allagents .main-heading .rightside-content .custom-option-field {
    max-width: 100%;
  }

  .gvcvtdvdvtdcvdtc .custom-option-field {
    max-width: 100% !important;
  }

  .viewdetail .main-heading2 .rightside-content .dropdown {
    flex: 0 0 100% !important;
  }
}

.allldrop {
  max-width: 131px !important;
  width: 100%;
}

@media (max-width: 600px) {
  .allldrop {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
}

.specialaccess {
  padding-bottom: 20px;
}

.speacial-wrapper {
  .main-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h6 {
      color: #fff;
      font-size: 22px;
      font-style: normal;
      font-family: "hsemibold";
      line-height: 24px;
      /* 109.091% */
      letter-spacing: 0.44px;
    }

    button {
      border: 1px solid #fff;
      background: #000;
      box-shadow: 4px 4px 0px 0px #fff;
      padding: 13px 45px;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-family: "hregular";
      line-height: 100%;
      /* 16px */
    }

    .rightside-content {
      max-width: 697px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .custom-option-field {
        max-width: 503px;
        width: 100%;
      }

      .dropdown {
        max-width: 170px;
        width: 100%;

        .dropdown-toggle {
          padding: 15px;
          width: 100%;
          box-shadow: none;
          height: 46px;

          &[aria-expanded="true"] {
            box-shadow: 4px 4px 0px 0px #fff;
          }

          &::after {
            display: none;
          }
        }

        .dropdown-menu {
          border: 1px solid #fff;
          background: #000;
          box-shadow: 4px 4px 0px 0px #fff;

          a {
            padding: 12px 15px;
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-family: "hregular";
            line-height: normal;
            border-bottom: 1px solid #f3f3f3;
            // &:last-child{
            //     border-bottom: 1px solid transparent;
            // }
            background: transparent;
          }
        }
      }
    }
  }
}

// .cal {
//   .rdrCalendarWrapper {
//     border-radius: 0px;
//     border: none;
//     background: transparent;
//     box-shadow: none;
//     width: 100%;
//     .rdrMonth {
//       width: 100%;
//     }
//   }

//   .rdrDateDisplayWrapper {
//     display: none;
//   }
// }
@media (max-width: 600px) {
  .allagents
    .main-heading
    .rightside-content
    .custom-option-field
    .positionset
    .btn {
    width: 110px !important;
    font-size: 12px !important;
  }

  .speacial-wrapper .main-heading {
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
  }

  .speacial-wrapper .main-heading .rightside-content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .speacial-wrapper .main-heading .rightside-content .dropdown {
    max-width: 100%;
  }

  .viewdetail .newheadingss {
    flex-direction: column;
  }

  .viewdetail .inner-heading .newspan {
    width: 100%;
    max-width: 100%;
    justify-content: center;
    align-items: center;
  }
}

.allldrop {
  max-width: 126px !important;
  width: 100%;
}

@media (max-width: 600px) {
  .allldrop {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
}

.drop-in-search-sticky {
  position: sticky;
  top: -5px;
  left: 0;
  width: 100%;
  background: #d1dbdf;
  z-index: 9;
}

.btnunblock {
  border: 1px solid #28e664 !important;
  color: #28e664 !important;

  svg path {
    fill: #28e664;
  }
}

.btnunblockmodal {
  background-color: #28e664 !important;
  color: #000 !important;
}

.bottom-cards33 {
  margin-top: 40px;

  h6 {
    color: #fff;
    font-size: 22px;
    font-style: normal;
    font-family: "hsemibold";
    line-height: 110%;
    margin-bottom: 23px;
  }

  p {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-family: "hsemibold";
    line-height: 120%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }
}

.pagination li.active a {
  background-color: #fff !important;
  color: #000;
}

.leadersfilter {
  background: #e2e9ec;
  border-radius: 0;
  padding: 0px;
  --bs-offcanvas-width: 500px;

  .offcanvas-header {
    display: flex;
    padding: 24px 20px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #797977;
    background: #e2e9ec;

    .offcanvas-title {
      color: #02171d;
      font-family: "f37";
      font-size: 20px;
      font-style: normal;
      line-height: 120%;
    }

    .btn-close {
      background: url("../../../assets/close-circle.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 17.844px;
      height: 18.603px;
      opacity: unset !important;
      padding: 0;
      margin: 0;
      box-shadow: none;
    }
  }

  .offcanvas-body {
    padding: 25px 20px 100px;

    .bottombtns {
      padding: 20px 20px 40px 20px;
      background: #e2e9ec;
      position: absolute;
      bottom: 0px;
      left: 0;
      right: 0;
      border-top: 1px solid #e6e6e6;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
      width: 100%;

      .whitebtn {
        border-radius: 4px;
        background: #d1dbdf;
        display: flex;
        padding: 16px 17.059px;
        justify-content: center;
        width: 100%;
        align-items: center;
        color: #02171d;
        text-align: center;
        font-family: "hmedium";
        border: none;
        font-size: 14px;
        font-style: normal;
        line-height: 120%;
      }

      .greybtn {
        border-radius: 4px;
        background: #329879;
        display: flex;
        padding: 16px 17.059px;
        justify-content: center;
        width: 100%;
        align-items: center;
        color: #e2e9ec;
        text-align: center;
        font-family: "hmedium";
        border: none;
        font-size: 14px;
        font-style: normal;
        line-height: 120%;
      }
    }

    .spanmain {
      display: flex;
      gap: 15px;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 15px;
      flex-wrap: wrap;

      .innerspan {
        border-radius: 4px;
        background: var(--Light-Mode-Primary-Text-Color, #02171d);
        display: flex;
        height: 30px;
        align-items: center;
        gap: 5px;
        color: #fff;
        font-family: "hregular";
        font-size: 12px;
        font-style: normal;
        line-height: 120%;
        padding: 0px 10px;
        white-space: nowrap;
      }
    }

    .dropdown {
      width: 100%;
      margin-bottom: 15px;

      .btn {
        display: flex;
        height: 46px;
        padding: 15px;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        color: #000;
        font-family: "hregular";
        font-size: 16px;
        font-style: normal;
        border-radius: 4px;
        background: #d1dbdf;
        width: 100%;
        line-height: 100%;
        border: none;
        outline: none;

        &::after {
          display: none;
        }
      }

      .dropdown-menu {
        width: 100%;
        border-radius: 4px;
        border: 1px solid #c0cbcf;
        background: #d1dbdf;
        padding: 0px;
        margin-top: 5px;
        max-height: 554px;
        overflow: auto;

        &::-webkit-scrollbar {
          display: none !important;
        }

        .set-custom-calendar-div {
          position: unset;
          background-color: transparent !important;
          box-shadow: none !important;
          border: none !important;
        }

        .cal .rdrCalendarWrapper {
          position: unset;
          width: 100%;
          position: unset;
          background-color: transparent !important;
          box-shadow: none !important;
          border: none !important;
        }

        .rdrMonth {
          width: 100%;
        }

        .rdrMonth .rdrWeekDays {
          width: 100%;
        }

        .searchinput {
          position: relative;

          .searchimg {
            position: absolute;
            top: 12px;
            right: 18px;
          }

          .innerinput {
            border: none;
            background-color: transparent;
            border-bottom: 1px solid #c0cbcf;
            width: 100%;
            outline: none;
            color: #000;
            font-family: "hregular";
            font-size: 14px;
            font-style: normal;
            line-height: 100%;
            padding: 12px 15px;

            &::placeholder {
              color: #77868b;
              font-family: "hregular";
              font-size: 14px;
              font-style: normal;
              line-height: 100%;
            }
          }
        }

        .inneritem {
          display: flex;
          padding: 12px 18px;
          align-items: center;
          gap: 8px;
          border-bottom: 1px solid #c0cbcf;

          .inneracccpara {
            color: #000;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-family: "hregular";
            line-height: normal;
          }
        }

        .accordion {
          background-color: transparent !important;
          border: none !important;
          outline: none !important;
          box-shadow: none !important;
          padding: 0 !important;
          margin: 0 !important;
          border-radius: 0 !important;

          .accordion-item {
            background-color: transparent !important;
            border: none !important;
            outline: none !important;
            box-shadow: none !important;
            padding: 0 !important;
            margin: 0 !important;
            border-radius: 0 !important;

            .level-1 {
              .accordion-button::after {
                left: 60px !important;
              }
            }

            .level-2 {
              .accordion-button::after {
                left: 80px !important;
              }
            }

            .level-3 {
              .accordion-button::after {
                left: 100px !important;
              }
            }

            .level-4 {
              .accordion-button::after {
                left: 120px !important;
              }
            }

            .level-5 {
              .accordion-button::after {
                left: 140px !important;
              }
            }

            .accordion-header {
              background-color: transparent !important;
              border: none !important;
              outline: none !important;
              box-shadow: none !important;
              padding: 0 !important;
              margin: 0 !important;
              border-radius: 0 !important;

              .accordion-button::after {
                margin: 0;
                width: 8px;
                height: 4px;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                background-image: url("../../../assets/newarrow.svg");
                padding: 0;
                position: absolute;
                left: 40px;
              }

              .accordion-button {
                background-color: transparent !important;
                border: none;
                border-bottom: 1px solid #c0cbcf;
                outline: none !important;
                box-shadow: none !important;
                padding: 10px 15px;
                margin: 0 !important;
                border-radius: 0 !important;

                .secondname {
                  padding-left: 34px;
                }

                .thirdname {
                  padding-left: 50px;
                }

                .fourname {
                  padding-left: 66px;
                }

                .maininnerbtn {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  gap: 24px;

                  .projectmain {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 6px;

                    .projectmainimg {
                      width: 24px;
                      height: 24px;
                      object-fit: cover;
                      object-position: center;
                      border-radius: 120px;
                      display: flex;
                      justify-content: center;
                      align-items: center;

                      .projectinnerimg {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                        border-radius: 120px;
                      }
                    }

                    .projectpara {
                      color: #000;
                      text-align: center;
                      font-size: 14px;
                      font-style: normal;
                      font-family: "hregular";
                      line-height: normal;
                    }
                  }
                }
              }
            }

            .accordion-body {
              background-color: transparent !important;
              border: none !important;
              outline: none !important;
              box-shadow: none !important;
              padding: 0 !important;
              margin: 0 !important;
              border-radius: 0 !important;

              .inneracc {
                border-bottom: 1px solid #c0cbcf;
                background: #d1dbdf;
                display: flex;
                padding: 10px 57px;
                align-items: center;
                gap: 8px;

                .inneracccpara {
                  color: #000;
                  text-align: center;
                  font-family: "hregular";
                  font-size: 14px;
                  font-style: normal;
                  line-height: normal;
                }
              }
            }
          }
        }
      }
    }
  }
}
