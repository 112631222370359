.institution-dashbaord {
  padding: 60px 0;
  padding-top: 44px !important;

  .main-heading {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h6 {
      color: var(--Primary-Text-Color, #02171d);
      font-family: "f37";
      font-size: 30px;
      font-style: normal;
      line-height: 24px;
      letter-spacing: 0.6px;
      text-transform: uppercase;
    }

    .custom-option-field {
      max-width: 574px;
      width: 100%;
    }
  }

  .bottom-cards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;

    .single-card {
      border-radius: 4px;
      background: #ecf5f9;
      height: 180px;
      flex: 0 0 24.4%;
      padding: 20px;
      position: relative;

      .gems-logo-img {
        width: 35px;
        height: 35px;
      }

      p {
        margin-top: 15px;
        margin-bottom: 10px;
        color: #77868b;
        font-family: "hregular";
        font-size: 14px;
        font-style: normal;
        line-height: 120%;
      }

      h6 {
        color: var(--Primary-Text-Color, #02171d);
        font-family: "f37";
        font-size: 18px;
        font-style: normal;
        line-height: 120%;
        letter-spacing: -0.18px;

        .green-text {
          color: #329879;
          font-family: "hregular";
          font-size: 14px;
          font-style: normal;
          line-height: 120%;
          letter-spacing: -0.14px;
        }

        .red-text {
          color: #fc4f52;
          font-family: "hregular";
          font-size: 14px;
          font-style: normal;
          line-height: 120%;
          letter-spacing: -0.14px;
        }
      }

      .bottom-text {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px 20px;
        margin-top: 10px;
        flex-wrap: wrap;

        .uni-text {
          opacity: 1;
          display: flex;
          align-items: center;
          gap: 5px;
          color: #021c24;
          font-family: "hregular";
          font-size: 14px;
          font-style: normal;
          line-height: 120%;
          letter-spacing: -0.14px;
          text-transform: uppercase;
          margin: 0;

          span {
            background: #c1cbce;
            border-radius: 4px;
            width: 3px;
            height: 14px;
            border-radius: 4px;
            display: flex;
            align-items: center;
          }
        }
      }

      .custom-tooltip-ico {
        position: absolute;
        top: 40px;
        right: 30px;
      }

      .set-custom-tooltip {
        position: absolute;
        top: 68px;
        right: 28px;
        border: 1px solid #fff;
        background: #000;
        box-shadow: 3px 3px 0px 0px #fff;
        padding: 20px;
        padding-top: 6px;
        max-width: 262px;
        width: 100%;
        z-index: 999;

        .inner-text {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 14px;

          h6 {
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-family: "hregular";
            line-height: 120%;
            /* 16.8px */
          }
        }
      }
    }
  }

  .dropdown {
    max-width: 170px;
    width: 100%;

    .dropdown-toggle {
      border-radius: 4px;
      background: #d1dbdf;
      color: #02171d !important;
      text-align: center;
      font-family: "hregular";
      font-size: 12px;
      font-style: normal;
      line-height: 100%;
      display: flex;
      height: 34px;
      padding: 11px 14px;
      align-items: center;
      border: none;

      .projectmain {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;

        .projectimg {
          width: 20px;
        }
      }

      &::after {
        display: none;
      }
    }

    .dropdown-menu {
      border-radius: 4px;
      background: #d1dbdf;
      border: 1px solid #c1cbce;
      padding: 0;

      a {
        padding: 12px 15px;
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-family: "hregular";
        line-height: normal;
        border-bottom: 1px solid #c1cbce;
        // &:last-child{
        //     border-bottom: 1px solid transparent;
        // }
        background: transparent;

        .projectmain {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 8px;

          .projectimg {
            width: 20px;
          }
        }
      }
    }
  }

  .parent-twice-custom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
  }
}

// .mainbuttonsss {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 20px;

//   h4 {
//     color: #fff;
//     font-size: 22px;
//     font-style: normal;
//     font-weight: 700;
//     line-height: 120%;
//     letter-spacing: -0.22px;
//   }

//   button {
//     background-color: transparent;
//     border: 1px solid #fff;
//     color: #fff;
//     padding: 10px 16px;
//   }
// }

// .mainbuttonssss {
//   display: flex;
//   justify-content: end;
//   align-items: center;
//   margin-bottom: 20px;

//   h4 {
//     color: #fff;
//     font-size: 22px;
//     font-style: normal;
//     font-weight: 700;
//     line-height: 120%;
//     letter-spacing: -0.22px;
//   }

//   button {
//     background-color: transparent;
//     border: 1px solid #fff;
//     color: #fff;
//     padding: 10px 16px;
//   }
// }

.welcomesss {
  color: var(--Primary-Text-Color, #02171d);
  font-family: "f37";
  font-size: 30px;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  margin-bottom: 40px;
  margin-top: 15px;
}

@media (max-width: 991px) {
  .institution-dashbaord .bottom-cards .single-card {
    flex: 0 0 48%;
    height: 200px;
    padding: 15px;
  }

  .institution-dashbaord .bottom-cards .single-card p {
    font-size: 12px;
  }

  .institution-dashbaord .bottom-cards .single-card h6 {
    font-size: 16px;
  }

  .institution-dashbaord .bottom-cards .single-card .bottom-text .uni-text {
    font-size: 12px;
  }

  .institution-dashbaord .bottom-cards .single-card .bottom-text {
    gap: 15px;
    flex-wrap: wrap;
  }

  .institution-dashbaord .main-heading {
    margin-top: 20px;
  }

  .mainbuttonsss {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
  }

  .mainbuttonsss button {
    width: 100%;
  }

  .institution-dashbaord .main-heading .custom-option-field {
    max-width: 100%;
  }

  .institution-dashbaord .bottom-cards .single-card p {
    word-break: break-all;
  }
}

.dtfdttcdvt6dtvd {
  .single-card {
    flex: 0 0 19.2% !important;
    height: 270px !important;
  }

  .single-card .bottom-text {
    flex-wrap: wrap !important;
  }
}

.newclassagent {
  .single-card {
    flex: 0 0 24.3% !important;
  }
}

@media (max-width: 1300px) {
  .dtfdttcdvt6dtvd {
    .single-card {
      flex: 0 0 18.2% !important;
    }
  }
}

@media (max-width: 991px) {
  .dtfdttcdvt6dtvd .single-card {
    flex: 0 0 48% !important;
  }
}

@media (max-width: 401px) {
  .institution-dashbaord .bottom-cards .single-card {
    height: 252px !important;
  }
}

@media (max-width: 376px) {
  .institution-dashbaord .bottom-cards .single-card .bottom-text {
    gap: 9px !important;
  }

  .institution-dashbaord .bottom-cards .single-card h6 {
    font-size: 12px !important;
  }
}

@media (max-width: 360px) {
  .institution-dashbaord .bottom-cards .single-card {
    height: 252px !important;
  }
}

// new ui scss here..................................

.custom-tab-bar {
  border-radius: 4px;
  background: #d1dbdf;
  box-shadow: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 240px;
  width: 100%;
  position: relative;
  height: unset !important;
  padding: 4px;

  a.active:hover {
    background: #329879;
    color: #fff;
    box-shadow: none;
    padding: 8px 12px !important;
    border-radius: 4px !important;
  }

  a {
    height: 26px !important;
    padding: 8px 12px !important;
    color: #02171d;
    font-family: "hmedium";
    font-size: 12px;
    font-style: normal;
    line-height: 120%;
    width: 100%;
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    &.active {
      background: #329879;
      color: #fff;
      box-shadow: none;
      padding: 8px 12px !important;
      border-radius: 4px !important;
    }
  }
}

// .cal {
//   .rdrCalendarWrapper {
//     position: absolute;
//     top: 50px;
//     z-index: 99;
//     border: 1px solid #fff;
//     background: #000;
//     box-shadow: 4px 4px 0px 0px #fff;
//     border-radius: 0;
//   }
//   .rdrDateDisplayWrapper {
//     display: none;
//   }
// }

// .mainbuttonsss button {
//   border: none !important;
//   color: #f2f2f2 !important;
//   font-size: 14px;
//   font-style: normal;
//   font-family: 'hregular';
//   line-height: 21px; /* 150% */
//   letter-spacing: -0.32px;
// }
.rdrDayNumber span {
  color: #000 !important;
  font-size: 14px;
  font-style: normal;
  font-family: "hregular";
  line-height: 21px;
  /* 150% */
  letter-spacing: -0.32px;
}

.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  background-color: #329879 !important;
  border-radius: 8px !important;
  border: none !important;
  color: #fff !important;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color: #fff !important;
  outline: none;
  border: none;
}

.institution-dashbaord .mainbuttonsss .gusvtsvtvtsvxs {
  display: none !important;
}

@media (max-width: 991px) {
  .custom-tab-bar {
    max-width: 100%;
  }

  .mainbuttonsss button {
    width: auto !important;
  }

  .institution-dashbaord .bottom-cards .single-card img {
    display: none !important;
  }

  .institution-dashbaord .bottom-cards .single-card {
    height: 100% !important;
    min-height: 150px;
  }

  .institution-dashbaord .bottom-cards .single-card p {
    margin-top: 0;
  }

  .institution-dashbaord .bottom-cards .single-card h6 span {
    font-size: 12px !important;
  }

  .datatable .main-heading {
    flex-direction: column;
    gap: 30px;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .mobile-spec-height {
    min-height: 216px !important;
  }

  .noofleaders-card {
    .bottom-text {
      flex-wrap: nowrap !important;
      flex-direction: column !important;
      align-items: flex-start !important;
      transition: 0.3s linear;
    }

    .twice-textbtn {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .showtext {
      height: 100%;
      opacity: 1;
    }

    .hidetext {
      opacity: 0;
      height: 0%;
    }

    .transform-img {
      transform: rotate(180deg);
    }
  }

  .institution-dashbaord .bottom-cards .single-card .arrow-down-icon {
    display: block !important;
  }

  .ordersetsm2 {
    order: 2;
  }

  .ordersetsm1 {
    order: 1;
  }

  .new-accordionmobile {
    display: block !important;
    border: 1px solid #fff;
    background: #000;

    .upper-body {
      padding: 22px 25px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 20px;

      .inner-text {
        flex: 0 0 46%;

        h6 {
          color: #8e8e8e;
          font-size: 12px;
          font-style: normal;
          font-family: "hregular";
          line-height: normal;
          margin-bottom: 8px;
        }

        p {
          color: #fff;
          font-size: 12px;
          font-style: normal;
          font-family: "hregular";
          line-height: 100%;
        }
      }
    }

    .accordion-item {
      display: flex !important;
      justify-content: center;
      align-items: center;
      flex-direction: column-reverse;
      border-radius: 0;
      background: #000;

      .accordion-header {
        width: 100%;
      }

      .accordion-button {
        border-top: 1px solid #fff;
        background: #000;
        border-radius: 0;
        padding: 14px 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        box-shadow: none;

        &::after {
          display: none;
        }

        & {
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-family: "hregular";
          line-height: 21px;
          letter-spacing: 0.28px;

          &[aria-expanded="true"] {
            img {
              transform: rotate(180deg);
            }
          }
        }
      }

      .accordion-body {
        background: #000;
        padding: 0px 25px;

        .inner-textt {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 20px 0;
          border-bottom: 1px solid #fafafa;

          &:last-child {
            border-bottom: 1px solid transparent;
          }

          p {
            color: #8e8e8e;
            font-size: 12px;
            font-style: normal;
            font-family: "hregular";
            line-height: 100%;
            letter-spacing: 0.24px;
          }

          h6 {
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-family: "hregular";
            line-height: 21px;
            /* 150% */
            letter-spacing: 0.28px;
          }
        }
      }
    }

    .accordion-item .accordion-collapse {
      width: 100%;
    }
  }

  .institution-dashbaord .mainbuttonsss .gusvtsvtvtsvxs {
    display: block !important;
    border-radius: 4px;
    background: #d1dbdf;
    box-shadow: none;
    color: #02171d !important;
    width: 100% !important;
  }

  .institution-dashbaord .bottom-cards .single-card .custom-tooltip-ico {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 999;
  }
}

@media (max-width: 600px) {
  .institution-dashbaord .bottom-cards .single-card h6 {
    word-break: break-all !important;
  }

  .institution-dashbaord .parent-twice-custom {
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .institution-dashbaord .dropdown {
    max-width: 100%;
  }

  .institution-dashbaord .dropdown .dropdown-toggle {
    width: 100% !important;
  }
}
