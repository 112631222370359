.login{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    .loginheader {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        border-bottom: 1px solid var(--Light-Mode-Strock-Color, #C1CBCE);
        background: rgba(209, 218, 221, 0.30);
        backdrop-filter: blur(15px);
        height: 70px;
        display: flex;
        align-items: center;
    }
    .login-card{
        max-width: 514px;
        width: 100%;
        border-radius: 4px;
        border: 1px solid #F1F1F1;
        background: #ECF5F9;
        padding: 30px 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h6{
            color: #02171D;
            text-align: center;
            font-family: "f37";
            font-size: 26px;
            font-style: normal;
            line-height: normal;
            margin: 30px 0px;
        }
        .option-field{
            width: 100%;
            position: relative;
            .password-icon{
                position: absolute;
                top: 20px;
                right: 16px;
            }
        }
        .custom-checkbox{
            width: 100%;
            display: block;
            // margin-bottom: 45px;
        }
    }
}

.blockuser-modal{
    h6{
        color: #FE0000;
        font-family: "f37";
        font-size: 20px;
        font-style: normal;
        text-align: center;
        line-height: 120%;
        margin-bottom: 20px;
    }
    a{
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-family: 'hmedium';
        line-height: 100%; 
        margin-bottom: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width:991px){
    .login .login-card{
        padding: 20px;
    }
    .login .login-card h6{
        font-size: 18px;
        text-align: center;
    }
    .login{
        padding-left: 15px;
        padding-right: 15px;
    }
    .blockuser-modal h6{
        font-size: 18px;
    }
    .blockuser-modal a{
        font-size: 18px;
    }
}