.insurance-detail {
  .main-heading {
    .dropdown {
      max-width: 170px;
      width: 100%;

      .dropdown-toggle {
        padding: 15px;
        width: 100%;
        height: 46px;
        border-radius: 12px;
        border: 1px solid #e6e6e6 !important;
        background: #fff;
        box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 14px;
        color: #000 !important;
        font-size: 16px;
        font-style: normal;
        font-family: 'hregular';
        line-height: 100%;

        .projectmain {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 8px;
        }

        &::after {
          display: none;
        }
      }

      .dropdown-menu {
        border-radius: 12px;
        border: 1px solid #e6e6e6;
        background: #fff;
        box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;

        a {
          padding: 12px 15px;
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-family: 'hregular';
          line-height: normal;
          border-bottom: 1px solid #e6e6e6;
          // &:last-child{
          //     border-bottom: 1px solid transparent;
          // }
          background: transparent;

          .projectmain {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;
          }
        }
      }
    }
  }
  .viewdetail-tabs {
    max-width: 610px;
    width: 100%;
    justify-content: space-between;
    border: 1px solid #e6e6e6 !important;
    border-radius: 5px;

    .nav-item {
      flex: 1;

      .nav-link {
        color: #000;
        font-size: 16px;
        font-style: normal;
             font-family: 'hsemibold';
        line-height: 110%;
        border: none;
        padding: 20px 10px;
        width: 100%;
        border-radius: 5px;
      }
    }

    .nav-item.show .nav-link,
    .nav-link.active {
      background: #000;
      border-radius: 0;
      border: none;
      color: #fff;
      font-size: 16px;
      font-style: normal;
           font-family: 'hsemibold';
      line-height: 110%;
      border-radius: 5px;
      /* 17.6px */
    }
  }
}

@media (max-width: 400px) {
  .insurance-detail .viewdetail-tabs .nav-item .nav-link {
    white-space: nowrap;
    font-size: 12px;
  }
}
