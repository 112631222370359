.dashboard {
  padding: 60px 0;
  padding-top: 44px !important;

  .new-graph {
    border-radius: 4px;
    background: #ecf5f9;
    padding: 20px 20px 40px 20px;
  }

  .main-heading {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h6 {
      color: var(--Primary-Text-Color, #02171d);
      font-family: "f37";
      font-size: 30px;
      font-style: normal;
      line-height: 24px;
      letter-spacing: 0.6px;
      text-transform: uppercase;
    }

    .custom-option-field {
      max-width: 574px;
      width: 100%;
    }
  }

  .bottom-cards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;

    .single-card {
      border-radius: 4px;
      background: #ecf5f9;
      border: none;
      box-shadow: none;
      min-height: 135px;
      flex: 0 0 32.8%;
      padding: 20px;
      position: relative;

      .twice-set-items {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 15px;
      }

      .cardinsidedrop {
        position: absolute;
        bottom: 20px;
        right: 20px;
        max-width: unset;
        width: auto;

        button {
          background-color: transparent;
          border: none;

          &::after {
            display: none;
          }

          &[aria-expanded="true"] {
            svg {
              transform: rotate(180deg);
            }
          }
        }

        .dropdown-menu {
          border-radius: 4px;
          background: #ecf5f9;
          border: 1px solid #c1cbce;
          box-shadow: none;
          padding: 0;
          min-width: 208px;
          left: unset !important;
          right: 0 !important;
          overflow: hidden;

          .text {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #c1cbce;
            padding: 10px 18px;

            &:last-child {
              border-bottom: none;
            }

            p {
              color: #77868b;
              font-family: "hmedium";
              font-size: 14px;
              font-style: normal;
              line-height: 120%;
              margin: 0;
            }

            h6 {
              color: var(--Black, #000);
              font-family: "hmedium";
              font-size: 14px;
              font-style: normal;
              line-height: 120%;
            }
          }
        }
      }

      .gems-logo-img {
        width: 35px;
        height: 35px;
      }

      p {
        color: #77868b;
        font-family: "hregular";
        font-size: 14px;
        font-style: normal;
        line-height: 120%;
        margin-bottom: 10px;
      }

      h6 {
        color: #021c24;
        font-family: "f37";
        font-size: 20px;
        font-style: normal;
        line-height: 120%;
        letter-spacing: -0.2px;
        display: flex;
        align-items: center;
        gap: 10px;

        .green-text {
          color: #329879;
          font-family: "hmedium";
          font-size: 14px;
          font-style: normal;
          line-height: 120%;
          letter-spacing: -0.14px;
        }

        .red-text {
          color: #fc4f52;
          font-family: "hmedium";
          font-size: 14px;
          font-style: normal;
          line-height: 120%;
          letter-spacing: -0.14px;
        }
      }

      .bottom-text {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px 20px;
        margin-top: 25px;
        flex-wrap: wrap;

        .uni-text {
          opacity: 1;
          display: flex;
          align-items: center;
          gap: 5px;
          color: #021c24;
          font-family: "hregular";
          font-size: 14px;
          font-style: normal;
          line-height: 120%;
          letter-spacing: -0.14px;
          text-transform: uppercase;
          margin: 0;

          span {
            border-radius: 4px;
            background: #c1cbce;
            width: 3px;
            height: 14px;
            display: flex;
            align-items: center;
          }
        }
      }

      .custom-tooltip-ico {
        position: absolute;
        top: 40px;
        right: 30px;
      }

      .set-custom-tooltip {
        position: absolute;
        top: 68px;
        right: 28px;
        border-radius: 12px;
        border: 1px solid #e6e6e6;
        background: #fff;
        box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
        padding: 20px;
        padding-top: 6px;
        max-width: 262px;
        width: 100%;
        z-index: 999;

        .inner-text {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin-top: 14px;
          flex-direction: column;
          border-bottom: 1px solid #fff;
          padding-bottom: 16px;

          &:last-child {
            border-bottom: 0;
            padding-bottom: 0px;
          }

          h6 {
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-family: "hregular";
            line-height: 120%;
            /* 16.8px */
          }

          .bottom-twice {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            flex-wrap: wrap;
            margin-top: 10px;

            p {
              display: flex;
              justify-content: center;
              align-items: center;
              opacity: 1;
              margin: 0;

              span {
                width: 3px;
                height: 14px;
                display: flex;
                border-radius: 4px;
                background: #fff;
                margin-right: 5px;
              }

              & {
                color: #fff;
                font-size: 14px;
                font-style: normal;
                font-family: "hregular";
                line-height: 120%;
                /* 16.8px */
                letter-spacing: -0.14px;
              }
            }
          }
        }
      }
    }
  }

  .dropdown {
    max-width: 170px;
    width: 100%;

    .dropdown-toggle {
      padding: 15px;
      width: 100%;
      height: 46px;
      border-radius: 12px;
      border: 1px solid #e6e6e6 !important;
      background: #fff;
      box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 14px;
      color: #000 !important;
      font-size: 16px;
      font-style: normal;
      font-family: "hregular";
      line-height: 100%;

      .projectmain {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
      }

      &::after {
        display: none;
      }
    }

    .dropdown-menu {
      border-radius: 12px;
      border: 1px solid #e6e6e6;
      background: #fff;
      box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;

      a {
        padding: 12px 15px;
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-family: "hregular";
        line-height: normal;
        border-bottom: 1px solid #e6e6e6;
        // &:last-child{
        //     border-bottom: 1px solid transparent;
        // }
        background: transparent;

        .projectmain {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 8px;
        }
      }
    }
  }

  .parent-twice-custom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
  }

  .upper-head-premium {
    color: var(--Primary-Text-Color, #02171d);
    font-family: "f37";
    font-size: 18px;
    font-style: normal;
    line-height: 120%;
    text-transform: uppercase;
    margin-top: 40px;
    margin-bottom: 15px;
  }
}

.mainbuttonsss {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .dropdown {
    max-width: 170px;
    width: 100%;

    .dropdown-toggle {
      display: flex;
      height: 36px;
      padding: 11px 14px;
      align-items: center;
      border-radius: 4px;
      background: #d1dbdf;
      color: #02171d;
      font-family: "hmedium";
      border: none;
      font-size: 12px;
      font-style: normal;
      line-height: 120%;
      gap: 12px;
      box-shadow: none !important;

      .projectmain {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
      }

      &::after {
        display: none;
      }
    }

    .dropdown-menu {
      border-radius: 4px;
      border: 1px solid #c0cbcf;
      background: #d1dbdf;
      margin: 0;
      padding: 0;
      margin-top: 5px !important;
      box-shadow: none !important;
      overflow: hidden;

      a {
        border-bottom: 1px solid #c0cbcf;
        display: flex;
        padding: 12px 18px;
        align-items: center;
        gap: 10px;
        color: #000;
        text-align: center;
        font-family: "hregular";
        font-size: 14px;
        font-style: normal;
        line-height: normal;

        &:hover {
          background: #c8d3d8 !important;
          transition: 0.3s;
        }

        .projectmain {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 8px;
        }
      }
    }
  }

  h4 {
    color: #fff;
    font-size: 22px;
    font-style: normal;
    font-family: "hsemibold";
    line-height: 120%;
    letter-spacing: -0.22px;
  }

  button {
    background-color: transparent;
    border: 1px solid transparent;
    color: #fff;
    padding: 10px 16px;
  }
}

.mainbuttonssss {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 20px;

  h4 {
    color: #fff;
    font-size: 22px;
    font-style: normal;
    font-family: "hsemibold";
    line-height: 120%;
    letter-spacing: -0.22px;
  }

  button {
    background-color: transparent;
    border: 1px solid #000;
    color: #000;
    padding: 10px 16px;
  }
}

.welcomesss {
  color: var(--Primary-Text-Color, #02171d);
  font-family: "f37";
  font-size: 30px;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  margin-bottom: 40px;
  margin-top: 15px;
}

@media (max-width: 991px) {
  .dashboard .bottom-cards .single-card {
    flex: 0 0 48%;
    height: 200px;
    padding: 15px;
  }

  .dashboard .bottom-cards .single-card p {
    font-size: 12px;
  }

  .dashboard .bottom-cards .single-card h6 {
    font-size: 16px;
  }

  .dashboard .bottom-cards .single-card .bottom-text .uni-text {
    font-size: 12px;
  }

  .dashboard .bottom-cards .single-card .bottom-text {
    gap: 15px;
    flex-wrap: wrap;
  }

  .dashboard .main-heading {
    margin-top: 20px;
  }

  .mainbuttonsss {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
  }

  .mainbuttonsss button {
    width: 100%;
  }

  .dashboard .main-heading .custom-option-field {
    max-width: 100%;
  }

  .dashboard .bottom-cards .single-card p {
    word-break: break-all;
  }
}

.dtfdttcdvt6dtvd {
  .single-card {
    flex: 0 0 19.2% !important;
    height: 229px !important;
  }

  .single-card .bottom-text {
    flex-wrap: wrap !important;
  }
}

.newclassagent {
  .single-card {
    flex: 0 0 24.3% !important;
  }
}

@media (max-width: 1300px) {
  .dtfdttcdvt6dtvd {
    .single-card {
      flex: 0 0 18.2% !important;
    }
  }
}

@media (max-width: 991px) {
  .dtfdttcdvt6dtvd .single-card {
    flex: 0 0 48% !important;
  }
}

@media (max-width: 401px) {
  .dashboard .bottom-cards .single-card {
    height: 252px !important;
  }
}

@media (max-width: 376px) {
  .dashboard .bottom-cards .single-card .bottom-text {
    gap: 9px !important;
  }

  .dashboard .bottom-cards .single-card h6 {
    font-size: 12px !important;
  }
}

@media (max-width: 360px) {
  .dashboard .bottom-cards .single-card {
    height: 252px !important;
  }
}

// new ui scss here..................................

.custom-tab-bar {
  border-radius: 12px;
  border: 1px solid #e6e6e6;
  background: #fff;
  box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 331px;
  width: 100%;
  position: relative;
  height: 46px;
  padding: 4px;

  a {
    padding: 8px 15px;
    color: #8c8c8c;
    font-size: 15px;
    font-style: normal;
    font-family: "hsemibold";
    line-height: 100%;
    text-align: center;
    width: 100%;
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;

    &.active {
      border-radius: 8px;
      background: #000;
      box-shadow: 0px -6px 0px 0px rgba(255, 255, 255, 0.15) inset;
      padding: 8px 15px;
      color: #fff;
    }
  }
}

.cal {
  .rdrCalendarWrapper {
    position: absolute;
    top: 50px;
    z-index: 99;
    border-radius: 12px;
    border: 1px solid #c1cbce;
    background: #ecf5f9;
    box-shadow: none;
  }

  .rdrDateDisplayWrapper {
    display: none;
  }
}

.mainbuttonsss button {
  border-radius: 8px;
  font-size: 14px;
  font-style: normal;
  font-family: "hregular";
  line-height: 21px;
  color: #fff !important;
  letter-spacing: -0.32px;

  span {
    color: #000 !important;
    border-radius: 8px !important;
  }
}

.rdrDayNumber span {
  color: #000 !important;
  font-size: 14px;
  font-style: normal;
  font-family: "hregular";
  line-height: 21px;
  /* 150% */
  letter-spacing: -0.32px;
  padding: 0px !important;

  &::after {
    background-color: #329879 !important;
  }
}

.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  background-color: #000 !important;
  border-radius: 0 !important;
  border: none !important;
  color: #fff !important;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color: #fff !important;
  background: #329879;
}

.dashboard .mainbuttonsss .gusvtsvtvtsvxs {
  display: none !important;
}

@media (max-width: 991px) {
  .dashboard .bottom-cards .single-card .cardinsidedrop .newmenu {
    right: initial !important;
    left: 0;
  }

  .custom-tab-bar {
    max-width: 100%;
  }

  .mainbuttonsss button {
    width: auto !important;
  }

  .dashboard .bottom-cards .single-card img {
    display: none !important;
  }

  .dashboard .bottom-cards .single-card {
    height: 100% !important;
    min-height: 216px;
  }

  .dashboard .bottom-cards .single-card p {
    margin-top: 0;
  }

  .dashboard .bottom-cards .single-card h6 span {
    font-size: 12px !important;
  }

  .datatable .main-heading {
    flex-direction: column;
    gap: 30px;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .mobile-spec-height {
    min-height: 199px !important;
  }

  .noofleaders-card {
    .bottom-text {
      flex-wrap: nowrap !important;
      flex-direction: column !important;
      align-items: flex-start !important;
      transition: 0.3s linear;
    }

    .twice-textbtn {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .showtext {
      height: 100%;
      opacity: 1;
    }

    .hidetext {
      opacity: 0;
      height: 0%;
    }

    .transform-img {
      transform: rotate(180deg);
    }
  }

  .dashboard .bottom-cards .single-card .arrow-down-icon {
    display: block !important;
  }

  .ordersetsm2 {
    order: 2;
  }

  .ordersetsm1 {
    order: 1;
  }

  .new-accordionmobile {
    display: block !important;
    border-radius: 4px !important;
    border: 1px solid #c0cbcf !important;
    background: #ecf5f9 !important;
    box-shadow: none !important;
    margin-bottom: 10px;
    overflow: hidden;

    .upper-body {
      padding: 22px 25px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 20px;

      .inner-text {
        flex: 0 0 46%;

        h6 {
          color: #77868b;
          font-family: "f37";
          font-size: 12px;
          font-style: normal;
          line-height: 120%;
          margin-bottom: 8px;
        }

        p {
          color: #02171d !important;
          font-family: "hmedium";
          font-size: 12px;
          font-style: normal;
          line-height: 120%;
        }
      }
    }

    .accordion-item {
      display: flex !important;
      justify-content: center;
      align-items: center;
      flex-direction: column-reverse;
      border-radius: 0;
      background: #ecf5f9 !important;
      overflow: hidden;

      .accordion-header {
        width: 100%;
      }

      .accordion-button {
        border-top: 1px solid #c0cbcf !important;
        border-bottom: none !important;
        background: #ecf5f9 !important;
        border-radius: 0;
        padding: 14px 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        box-shadow: none;

        &::after {
          display: none;
        }

        & {
          color: #000 !important;
          font-size: 14px;
          font-style: normal;
          font-family: "hregular";
          line-height: 21px;
          letter-spacing: 0.28px;

          &[aria-expanded="true"] {
            img {
              transform: rotate(180deg);
            }
          }
        }
      }

      .accordion-body {
        background: #ecf5f9 !important;
        padding: 0px 25px;

        .inner-textt {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 20px 0;
          border-bottom: 1px solid #c0cbcf !important;

          &:last-child {
            border-bottom: 1px solid transparent;
          }

          p {
            color: #77868b;
            font-family: "f37";
            font-size: 12px;
            font-style: normal;
            line-height: 100%;
            letter-spacing: 0.24px;
          }

          h6 {
            color: #02171d !important;
            font-family: "hmedium";
            font-size: 14px;
            font-style: normal;
            line-height: 21px;
            letter-spacing: 0.28px;

            a {
              color: #02171d !important;
            }
          }
        }
      }
    }

    .accordion-item .accordion-collapse {
      width: 100%;
    }
  }

  .dashboard .mainbuttonsss .gusvtsvtvtsvxs {
    display: block !important;
    border-radius: 6px;
    background: #329879;
    width: 100% !important;
  }

  .dashboard .bottom-cards .single-card .custom-tooltip-ico {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 999;
  }
}

@media (max-width: 600px) {
  .dashboard .bottom-cards .single-card h6 {
    word-break: break-all !important;
  }

  .dashboard .parent-twice-custom {
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .dashboard .dropdown {
    max-width: 100%;
  }

  .dashboard .dropdown .dropdown-toggle {
    width: 100% !important;
  }
}

.leader-data {
  margin-top: 40px;

  .leader-heading {
    margin-bottom: 12px;

    h6 {
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-family: "hsemibold";
      line-height: 24px;
      letter-spacing: 0.32px;
    }
  }

  .datatable .table-responsive {
    border: 1px solid #161616 !important;
  }

  .datatable .table-responsive td {
    border-top: 1px solid #161616 !important;
    border-bottom: 1px solid #161616 !important;
  }
}

@media (max-width: 600px) {
  .leader-data .twice-tables-set .datatable .table-responsive {
    display: block !important;
  }

  .leader-data .twice-tables-set {
    flex-direction: row !important;
  }

  .leader-data .twice-tables-set .datatable {
    flex: 0 0 49% !important;
  }

  .leader-data .leader-heading h6 {
    font-size: 14px;
  }

  .leader-data .datatable .table-responsive td {
    padding: 10px !important;

    &:first-child {
      max-width: 10px;
    }
  }

  .leader-data .datatable .table-responsive th {
    display: none !important;
  }
}

.twice-charts {
  margin-top: 20px;

  .apexcharts-toolbar {
    display: none;
  }

  & {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .main-graph {
    width: 49%;
    border-radius: 4px;
    background: #ecf5f9;
    padding: 20px;

    .graphheading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 17px;

      .seealllink {
        color: var(--Light-Mode-Secondary-Text-Color, #77868b);
        font-family: "hmedium";
        font-size: 12px;
        font-style: normal;
        line-height: 120%;
        text-transform: uppercase;
        white-space: nowrap;
      }

      .main-heading {
        color: var(--Primary-Text-Color, #02171d);
        font-family: "f37";
        font-size: 15.295px;
        font-style: normal;
        line-height: 120%;
        text-transform: uppercase;
        margin: 0;
      }
    }
  }
}

.bottom-cardsnewstyle {
  justify-content: flex-start !important;
}

@media (max-width: 600px) {
  .twice-charts {
    flex-direction: column;
  }

  .twice-charts .main-graph {
    width: 100%;
  }

  .datatable .viewdetail-tabs {
    flex-wrap: nowrap !important;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
