.datatable {
  .main-heading {
    margin: 30px 0;

    h6 {
      color: #000;
      font-size: 22px;
      font-style: normal;
      font-family: 'hsemibold';
      line-height: 24px;
      letter-spacing: 0.44px;
    }
  }

  .table-responsive {
    border: none !important;
    border-radius: 4px 4px 0px 0px !important;
    background: #ECF5F9 !important;

    &::-webkit-scrollbar {
      display: none !important;
    }

    .table {
      margin: 0;
    }

    th {
      color: #77868B !important;
      font-family: "f37" !important;
      font-size: 12px !important;
      font-style: normal !important;
      font-weight: 500 !important;
      line-height: 120% !important;
      letter-spacing: 0.24px !important;
      vertical-align: middle !important;
      border: none !important;
      height: 48px !important;
      padding: 10px 18px !important;
      position: relative;
      white-space: nowrap;
      background-color: transparent;
    }

    td {
      color: #02171D !important;
      font-family: "hmedium" !important;
      font-size: 12px !important;
      font-style: normal !important;
      line-height: 110% !important;
      letter-spacing: 0.24px !important;
      vertical-align: middle !important;
      border-top: 1px solid #C0CBCF !important;
      background: #ECF5F9 !important;
      height: 54px !important;
      padding: 10px 18px !important;
      white-space: nowrap;
      background-color: transparent;

      .codepara p {
        color: #02171D;
        font-family: "hmedium" !important;
        font-size: 12px;
        font-style: normal;
        line-height: 110%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        letter-spacing: 0.24px;
      }

      .twice-text {
        h6 {
          color: #02171D !important;
          font-family: "hmedium" !important;
          font-size: 12px !important;
          font-style: normal !important;
          line-height: 110% !important;
          letter-spacing: 0.1px !important;
        }

        p {
          color: #77868B !important;
          font-family: "hmedium" !important;
          font-size: 12px !important;
          font-style: normal !important;
          line-height: 110% !important;
          letter-spacing: 0.1px !important;
          margin-top: 2px;
        }
      }

      &:last-child {
        margin-left: auto;
      }

      .forend {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .common-btn {
          padding: 13px 25px;
          font-family: 'hsemibold';
          font-size: 16px;
          line-height: 100%;
        }
      }
    }
  }


  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    // border-top: 1px solid #F2F2F2;
    border-radius: 0px 0px 4px 4px;
    background: #ECF5F9;
    margin-top: 0 !important;

    .page-item.active .page-link {
      border-radius: 5px !important;
      box-shadow: none !important;
      background: #C1EBE2 !important;
      color: #329879 !important;
    }

    .left-f {
      h6 {
        color: #77868B;
        font-family: "hmedium";
        font-size: 12px;
        font-style: normal;
        line-height: 110%;
        letter-spacing: 0.1px;
      }
    }

    .right-f {
      .page-link:focus {
        box-shadow: none;
        background-color: unset;
      }

      .page-link:hover {
        box-shadow: none;
        background-color: unset;
      }

      .page-link {
        border: none;
        color: #869397;
        font-family: "f37";
        font-size: 15px;
        font-style: normal;
        line-height: 100%;
        background-color: transparent;
        padding: 6px 11px;
        border-radius: 5px;

        &.active {
          background: #C1EBE2;
          color: #329879;
        }
      }

      .page-item:first-child .page-link {
        color: #869397;
        font-family: "f37";
        font-size: 15px;
        font-style: normal;
        line-height: 100%;
      }
    }
  }

  .viewdetail-tabs {
    max-width: fit-content !important;
    width: 100%;
    justify-content: space-between;
    border: 1px solid #E6E6E6 !important;
    border-radius: 4px;
    background: #D9E2E5;
    padding: 4px 2px;
    gap: 5px;

    .nav-item {
      flex: 1;

      .nav-link {
        border-radius: 4px !important;
        background: #D9E2E5 !important;
        display: flex !important;
        padding: 8px 20px !important;
        justify-content: center !important;
        align-items: center !important;
        color: #77868B !important;
        font-family: "f37" !important;
        white-space: nowrap !important;
        font-size: 14px !important;
        font-style: normal !important;
        line-height: 100% !important;
        text-transform: uppercase !important;
        height: unset !important;
      }
    }

    .nav-item.show .nav-link,
    .nav-link.active {
      background: #C1ECE3 !important;
      color: #329879 !important;
    }
  }
}

.filter-btn {
  display: flex;
  flex-direction: column;
  width: 11px;
  margin-left: 10px;
}

.parent-tag {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.mobileviewnew {
  display: none !important;
}

.accordion .destopviewss {
  display: block !important;
}

@media (max-width: 650px) {
  .mobileviewnew {
    display: block !important;
  }

  .accordion .destopviewss {
    display: none !important;
  }

  .table-responsive {
    display: none;
  }

  .accmblview {
    display: block !important;
    border: 1px solid #fff;
    background: #000;
    backdrop-filter: blur(3px);

    .main-twice-text {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 25px 28px;
      padding-right: 61px;

      .accmblviewhead {
        color: #fff;
        font-size: 14px;
        font-family: 'hsemibold';
        line-height: 24px;
        letter-spacing: 0.28px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .arrowsacc {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .accordion {
      border: none !important;
      background: transparent !important;
      box-shadow: none !important;
      outline: none !important;
      border-radius: none !important;

      .accordion-button::after {
        background: url("../../../../assets/accarrowdown.svg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 8.571px;
        height: 5.143px;
        position: absolute;
        top: 28px;
        right: 25px;
      }

      .accordion-item {
        border: none !important;
        background: transparent !important;
        box-shadow: none !important;
        outline: none !important;
        border-radius: none !important;

        .accordion-header {
          border: none !important;
          background: transparent !important;
          box-shadow: none !important;
          outline: none !important;
          border-radius: none !important;

          .accordion-button {
            border-top: 1px solid #fff;
            border-bottom: 1px solid #fff;
            background: var(--login-bg);
            padding: 22px 25px;
            padding-right: 61px;

            &:focus {
              box-shadow: none;
            }

            .accheadermain {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 30px;
              width: 100%;

              .accmainimage {
                width: 30px;
                height: 30px;
                border-radius: 60px;
                background: rgba(0, 0, 0, 0.04);
                object-fit: cover;
                object-position: center;
                display: flex;
                align-items: center;

                .accinnerimg {
                  width: 100%;
                  height: 100%;
                  border-radius: 60px;
                  background: rgba(0, 0, 0, 0.04);
                  object-fit: cover;
                  object-position: center;
                }
              }

              .acctext {
                color: #fff;
                font-size: 14px;
                font-style: normal;
                font-family: 'hsemibold';
                line-height: 100%;
              }
            }
          }
        }

        .accordion-body {
          border: none !important;
          background: transparent !important;
          box-shadow: none !important;
          outline: none !important;
          border-radius: none !important;
          padding: 0px 25px;

          .acctexts {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px 0px;
            border-bottom: 1px solid #1c1c1c;

            &:last-child {
              margin-bottom: 0px;
            }

            .textleft {
              color: #fff;
              font-size: 14px;
              font-style: normal;
              font-family: 'hsemibold';
              line-height: 100%;
              letter-spacing: 0.28px;
              text-transform: uppercase;
            }

            .textright {
              color: #fff;
              font-size: 14px;
              font-style: normal;
              font-family: 'hsemibold';
              line-height: 21px;
              letter-spacing: 0.28px;
            }

            .brdrbtn {
              border: 1px solid #fff;
              background: #000;
              box-shadow: 2px 2px 0px 0px #fff;
              display: flex;
              padding: 7px 15px;
              justify-content: center;
              align-items: center;
              color: #fff;
              font-size: 12px;
              font-style: normal;
              font-family: 'hsemibold';
              line-height: 120%;
              text-transform: uppercase;
            }
          }
        }
      }
    }

    .twice-text {
      h6 {
        color: #fff;
        font-size: 12px;
        font-style: normal;
        font-family: 'hsemibold';
        line-height: 21px;
        /* 175% */
        letter-spacing: 0.24px;
      }

      p {
        color: #fff;
        font-size: 12px;
        font-style: normal;
        font-family: 'hregular';
        line-height: 21px;
        /* 175% */
        letter-spacing: 0.24px;
        margin-top: 2px;
      }
    }
  }

  .accmblview .accordion .accordion-item .accordion-header .accordion-button {
    box-shadow: none !important;
  }

  .footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    // border-top: 1px solid #F2F2F2;
    background-color: transparent;

    .left-f {
      h6 {
        font-style: normal;
        font-family: 'hmedium';
        font-size: 14px;
        line-height: 100%;
        color: #a0a1a1;
      }
    }

    .right-f {
      .page-link:focus {
        box-shadow: none;
        background-color: unset;
      }

      .page-link:hover {
        box-shadow: none;
        background-color: unset;
      }

      .page-link {
        border: none;
        color: #a2a4a6;
        font-size: 15px;
        font-style: normal;
        font-family: 'hsemibold';
        line-height: 100%;
        /* 15px */
        background-color: transparent;

        &.active {
          background: #fff;
          color: #000;
        }
      }

      .page-item:first-child .page-link {
        font-style: normal;
        font-family: 'hmedium';
        font-size: 15px;
        line-height: 100%;
        color: #a0a1a1;
      }
    }
  }

  .dashboardetail .auctiondetailhead {
    font-size: 20px;
  }
}

.ysvtvstyvtiivtyvs {
  opacity: 0 !important;
  width: 0 !important;
  height: 0 !important;
  visibility: hidden !important;

  td {
    opacity: 0 !important;
    width: 0 !important;
    height: 0 !important;
    visibility: hidden !important;
    padding: 0 !important;
  }
}

.page-item.active {
  .page-link {
    border-radius: 5px !important;
    background: #000 !important;
    color: #FFF !important;
    box-shadow: 0px -4px 0px 0px rgba(255, 255, 255, 0.20) inset !important;
  }
}

.custom-tab-bar a.active:hover {
  border-radius: 4px;
  background: #329879 !important;
}

@media (max-width: 376px) {
  .datatable .viewdetail-tabs .nav-item .nav-link {
    font-size: 13px;
  }
}

.twice-tables-set {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  .datatable {
    flex: 1 0 48%;

    .table-responsive {
      overflow: visible !important;
    }
  }
}

@media (max-width: 600px) {
  .twice-tables-set {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .twice-tables-set .datatable {
    flex: 0 0 100%;
    width: 100%;
  }
}



.datatable td .dropdownnodesminers {
  .dropdown-toggle {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    outline: none;
    padding: 0;
  }

  .dropdown-menu {
    left: unset !important;
    right: 0 !important;
    border-radius: 4px;
    border: 1px solid var(--Stroke, #C0CBCF);
    background: #E2E9EC;
    box-shadow: 0px 14px 34px 0px rgba(0, 0, 0, 0.04);
    padding: 0;

    .twice {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 18px;
      border-bottom: 1px solid var(--Stroke, #C0CBCF);
      background: #D1DBDF;

      p {
        color: var(--Secondary-Text-Color, #77868B);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-family: "hregular";
        line-height: normal;
      }

      h6 {
        color: var(--Primary-Text-Color, #02171D);
        font-size: 14px;
        font-style: normal;
        font-family: "hmedium";
        line-height: normal;
      }
    }
  }
}


 


.datatable .inner-textt  .dropdownnodesminers {
  .dropdown-toggle {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    outline: none;
    padding: 0;
    justify-content: flex-end;
  }

  .dropdown-menu {
    left: unset !important;
    right: 0 !important;
    border-radius: 4px;
    border: 1px solid var(--Stroke, #C0CBCF);
    background: #E2E9EC;
    box-shadow: 0px 14px 34px 0px rgba(0, 0, 0, 0.04);
    padding: 0;

    .twice {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 18px;
      border-bottom: 1px solid var(--Stroke, #C0CBCF);
      background: #D1DBDF;

      p {
        color: var(--Secondary-Text-Color, #77868B);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-family: "hregular";
        line-height: normal;
      }

      h6 {
        color: var(--Primary-Text-Color, #02171D);
        font-size: 14px;
        font-style: normal;
        font-family: "hmedium";
        line-height: normal;
      }
    }
  }
}