.alltransaction {
  padding: 60px 0;

  .main-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h6 {
      color: var(--Primary-Text-Color, #02171D);
      font-family: "f37";
      font-size: 30px;
      font-style: normal;
      line-height: 24px;
      letter-spacing: 0.6px;
      text-transform: uppercase;
    }

    button {
      border-radius: 4px;
      background: #D1DBDF;
      display: flex;
      height: 34px;
      padding: 8px 12px;
      align-items: center;
      gap: 12px;
      color: #02171D;
      font-family: "hmedium";
      font-size: 12px;
      font-style: normal;
      line-height: 120%;
      border: none;
      box-shadow: none !important;
    }

    .dropdown {
      max-width: 180px;
      width: 100%;

      .dropdown-toggle {
        padding: 17px;
        width: 100%;
        border-radius: 4px;
        background: #D1DBDF;
        box-shadow: none;
        height: 40px;
        color: #000 !important;
        border: none;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .projectmain {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 8px;

          .projectimg {
            width: 25px;
          }
        }

        &::after {
          display: none;
        }
      }

      .dropdown-menu {
        border-radius: 4px;
        background: #D1DBDF;
        border: 1px solid #C1CBCE;
        width: 100%;
        overflow: hidden;
        padding: 0;

        a {
          padding: 12px 15px;
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-family: 'hregular';
          line-height: normal;
          border-bottom: 1px solid #C1CBCE;
          // &:last-child{
          //     border-bottom: 1px solid transparent;
          // }

          .projectmain {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;

            .projectimg {
              width: 25px;
            }
          }
        }
      }
    }

    .rightside-content {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;

      .whitnewbtn {
        border-radius: 4px;
        background: #D1DBDF;
        display: flex;
        height: 34px;
        padding: 8px 12px;
        align-items: center;
        gap: 12px;
        color: #02171D;
        font-family: "hmedium";
        font-size: 12px;
        font-style: normal;
        line-height: 120%;
        border: none;
        box-shadow: none !important;
      }

      .custom-option-field {
        max-width: 437px;
        width: 100%;

        .positionset {
          position: absolute;
          top: 0;
          left: 0;

          .btn {
            border-top-right-radius: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 7px;
            border-bottom-right-radius: 0px;
            width: unset !important;
            border-right: 1px solid #D0DEE4 !important;

            &:hover {
              background: transparent !important;
              color: #02171D;
            }
          }
        }
      }

      .dropdown {
        max-width: 170px;
        width: 100%;

        .dropdown-toggle {
          padding: 15px;
          width: 100%;
          box-shadow: none;
          height: 34px;

          &[aria-expanded="true"] {
            box-shadow: none;
          }

          &::after {
            display: none;
          }
        }

        .dropdown-menu {
          border-radius: 4px;
          background: #D1DBDF;
          border: 1px solid #C1CBCE;
          padding: 0;
          box-shadow: none !important;
          overflow: hidden;

          a {
            padding: 12px 15px;
            color: #02171D;
            font-family: "hregular";
            font-size: 12px;
            font-style: normal;
            line-height: 100%;
            border-bottom: 1px solid #C1CBCE;
            // &:last-child{
            //     border-bottom: 1px solid transparent;
            // }
            background: transparent;
          }
        }
      }
    }
  }

  .main-heading {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h6 {
      color: #000;
      font-family: "f37";
      font-size: 30px;
      font-style: normal;
      line-height: 24px;
      letter-spacing: 0.6px;
      text-transform: uppercase;
    }

    .set-twice-text {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 15px;
      margin-bottom: 15px;

      h6 {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-family: 'hregular';
        line-height: normal;
      }
    }
  }

  .main-heading111 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    button {
      border: 1px solid #fff;
      background: #000;
      box-shadow: 4px 4px 0px 0px #fff;
      padding: 13px 45px;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-family: 'hregular';
      line-height: 100%;
      /* 16px */
    }

    .rightside-content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .custom-option-field {
        width: 91%;
      }

      .dropdown {
        max-width: 98px;
        width: 100%;

        .dropdown-toggle {
          border-right: 1px solid #D0DEE4 !important;
          display: flex;
          padding: 0px 15px;
          align-items: center;
          gap: 7px;
          color: var(--Primary-Text-Color, #02171D);
          font-family: "hregular";
          border: none;
          font-size: 14px;
          font-style: normal;
          justify-content: center;
          line-height: 100%;
          height: 34px;

          &::after {
            display: none;
          }
        }

        .dropdown-menu {
          border: 1px solid #C1CBCE !important;
          border-radius: 4px;
          background: #D1DBDF;
          padding: 0;


          a {
            padding: 12px 15px;
            color: #000 !important;
            font-size: 14px;
            font-style: normal;
            font-family: 'hregular';
            line-height: normal;
            border-bottom: 1px solid #C1CBCE !important;
            // &:last-child{
            //     border-bottom: 1px solid transparent;
            // }
          }
        }
      }
    }
  }

  .bottom-cards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;

    .single-card {
      border-radius: 4px;
      background: #ECF5F9;
      height: 200px;
      flex: 0 0 32.8%;
      padding: 25px;

      .gems-logo-img {
        width: 35px;
        height: 35px;
      }

      p {
        margin-top: 15px;
        margin-bottom: 10px;
        color: #77868B;
        font-family: "hregular";
        font-size: 14px;
        font-style: normal;
        line-height: 120%; 
      }

      h6 {
        color: #021C24;
        font-family: "f37";
        font-size: 18px;
        font-style: normal;
        line-height: 120%; 
        letter-spacing: -0.18px;
      }

      .bottom-text {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        margin-top: 10px;
        flex-wrap: wrap;

        .uni-text {
          opacity: 1;
          display: flex;
          align-items: center;
          gap: 5px;
          color: #021C24;
          font-family: "hregular";
          font-size: 14px;
          font-style: normal;
          line-height: 120%; 
          letter-spacing: -0.14px;
          margin: 0;

          span {
            border-radius: 4px;
            background: #C1CBCE;
            width: 3px;
            height: 14px;
            border-radius: 4px;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }

  .custom-option-field {
    position: relative;

    .transaction-inner-drop {
      position: absolute;
      top: 0;
      left: 0;
      max-width: 130px !important;
      // padding-right: 25px;

      .dropdown-toggle {
        background-color: transparent !important;
        border: none !important;
        box-shadow: none !important;
      }
    }

    input {
      padding-left: 100px;
    }
  }
}

@media (max-width: 991px) {
  .dropdown-menu {
    width: 100%;
  }

  .alltransaction .bottom-cards .single-card {
    flex: 0 0 48%;
    min-height: 215px;
    height: 100%;
    padding: 15px;

    &:first-child {
      flex: 0 0 100%;
    }
  }

  .alltransaction .bottom-cards .single-card p {
    font-size: 12px;
  }

  .alltransaction .bottom-cards .single-card h6 {
    font-size: 16px;
  }

  .alltransaction .bottom-cards .single-card .bottom-text .uni-text {
    font-size: 12px;
  }

  .alltransaction .bottom-cards .single-card .bottom-text {
    flex-wrap: wrap;
    gap: 15px;
  }

  .alltransaction .bottom-cards .single-card img {
    display: none;
  }

  .alltransaction .main-heading111 .rightside-content {
    flex-direction: column;
    gap: 15px;
  }

  .alltransaction .main-heading111 .rightside-content .custom-option-field {
    width: 100%;
  }

  .alltransaction .main-heading111 .rightside-content .dropdown {
    max-width: 100%;
  }

  .alltransaction .main-heading111 .rightside-content .dropdown .dropdown-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 376px) {
  .alltransaction .bottom-cards .single-card h6 {
    font-size: 12px !important;
  }
}

@media (max-width: 600px) {
  .alltransaction .bottom-cards .single-card h6 {
    word-break: break-all !important;
  }

  .alltransaction .custom-option-field .transaction-inner-drop {
    max-width: 115px !important;
  }

  .alltransaction .custom-option-field input {
    padding-left: 133px;
  }

  .alltransaction .main-heading {
    flex-direction: column;
    gap: 8px;
    justify-content: flex-start;
    align-items: flex-start;

    .multi-items {
      width: 100%;
    }
  }

  .alltransaction .main-heading .rightside-content .whitnewbtn {
    width: 100%;
  }

  .leadersfilter .offcanvas-body .dropdown .dropdown-menu .inneritem .inneracccpara {
    font-size: 13px;
  }

  .alltransaction .main-heading .rightside-content {
    flex-direction: column;
  }
}

.new-transaction {
  .main-heading111 .rightside-content .custom-option-field {
    width: 100% !important;
  }
}