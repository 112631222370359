.salesround {
  .main-heading {
    .tokensettingsbtns {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      flex-direction: row;

      .zdgvzfxv {
        white-space: nowrap;
        height: 36px !important;
        color: #02171D;
        font-family: "hregular";
        font-size: 10px;
        font-style: normal;
        line-height: 110%;
        letter-spacing: 0.1px;
      }

      .inputsizee {
        width: 307px;
        height: 36px;
      }

      .dropdown {
        max-width: 170px;
        width: 100%;

        .dsfdsf {
          width: 110px !important;
          justify-content: space-between;
        }

        .dropdown-toggle {
          padding: 15px;
          width: 100%;
          box-shadow: none;
          height: 46px;
          border-radius: 12px;
          border: 1px solid #e6e6e6;
          background: #fff;
          box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
          display: flex;
          align-items: center;
          gap: 5px;

          &::after {
            display: none;
          }
        }

        .dropdown-menu {
          border-radius: 12px;
          border: 1px solid #e6e6e6;
          background: #fff;
          box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;

          a {
            padding: 12px 15px;
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-family: 'hregular';
            line-height: normal;
            border-bottom: 1px solid #e6e6e6;
            // &:last-child{
            //     border-bottom: 1px solid transparent;
            // }
            background: transparent;
          }
        }
      }

      .dropdown {
        max-width: 170px;
        width: 100%;

        .dropdown-toggle {
          width: 100%;
          height: 36px;
          padding: 0px 14px !important;
          border-radius: 4px;
          background: #D1DBDF;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 14px;
          color: #02171D;
          font-family: "hregular";
          font-size: 10px;
          font-style: normal;
          line-height: 110%;
          letter-spacing: 0.1px;

          .projectmain {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;
          }

          &::after {
            display: none;
          }
        }

        .dropdown-menu {
          padding: 0;
          overflow: hidden;
          border: 1px solid #C1CBCE;
          border-radius: 4px;
          background: #D1DBDF;
          box-shadow: none;

          a {
            padding: 12px 15px;
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-family: 'hregular';
            line-height: normal;
            border-bottom: 1px solid #C1CBCE;
            // &:last-child{
            //     border-bottom: 1px solid transparent;
            // }
            background: transparent;

            .projectmain {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 8px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .salesround .main-heading {
    flex-direction: column;
    gap: 20px;
  }
}

@media (max-width: 600px) {
  .salesround .main-heading {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .salesround .main-heading .tokensettingsbtns {
    flex-direction: column;
    width: 100%;

    .custom-option-field {
      width: 100%;
    }
  }

  .salesround .main-heading .tokensettingsbtns .inputsizee {
    width: 100% !important;
  }

  .salesround .main-heading .tokensettingsbtns .dropdown {
    width: 100%;
    max-width: 100%;
  }

  .salesround .main-heading .tokensettingsbtns .dropdown .dsfdsf {
    width: 100% !important;
  }

  .salesround .main-heading .tokensettingsbtns .zdgvzfxv {
    width: 100% !important;
  }
}



.btn-savechanges {
  border-radius: 4px;
  background: #329879 !important;
  padding: 11px 14px;
  color: #FFF !important;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-family: "hregular";
  line-height: 100%;
  max-width: 200px;
}

@media (max-width:600px){
  .btn-savechanges{
    max-width: 100% !important;
  }
}