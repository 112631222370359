.main-navbar {
    height: 70px;
    border-bottom: 1px solid #C1CBCE;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    .twice-icons {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 25px;
    }
}

@media (max-width:991px) {
    .d-noneonmobile {
        display: none;
    }

    .navbar-collapse {
        display: none;
    }

    .logout-btn {
        display: flex !important;
        position: static;
        bottom: 30px;
        left: 20px;
        width: 100% !important;
        margin: 0 auto;
        max-width: 100% !important;

    }

    // .custom-sidebar{
    //     position: relative;
    //     z-index: 99999999;
    // }
}




.connectwallet-modal {
    // background: rgba(0, 0, 0, 0.85);
    backdrop-filter: blur(4px);
    z-index: 99999999999;

    .modal-dialog {
        max-width: 520px;
    }

    .modal-content {
        border-radius: 4px;
        background: #E2E9EC;
        padding: 0px;
    }

    .modal-header {
        border-bottom: 1px solid #797977;
        background: #E2E9EC;
        border-radius: 0;
        padding: 24px 20px;
        justify-content: space-between;

        .modal-title {
            color: #02171D;
            font-family: "f37";
            font-size: 20px;
            font-style: normal;
            line-height: 120%;
        }

        .btn-close {
            background: url("../../../assets/close-circle.svg");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            border: none;
            border: none;
            box-shadow: none;
            padding: 0;
            outline: none;
            opacity: unset;
            width: 24px;
            height: 24px;
            margin: 0;
        }
    }

    .modal-body {
        padding: 20px 25px 20px;

        .darktext {
            background: #080808;
            padding: 20px 10px 10px 20px;
            margin-top: 15px;
            margin-bottom: 30px;

            .scrolltext {
                height: 380px;
                overflow: auto;
                padding-right: 5px;

                &::-webkit-scrollbar {
                    width: 4px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    background: #2B2A2A;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: #FFF;
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: #FFF;
                }

                .formarginleft {
                    margin-left: 30px;
                }

                .darkpara {
                    color: #FFF;
                    font-size: 14px;
                    font-style: normal;
                    font-family: 'hregular';
                    line-height: 117%;
                    margin-bottom: 14px;

                    .bold {
                        font-family: 'hsemibold';
                    }

                    .headingtext {
                        color: #FFF;
                        font-size: 14px;
                        font-style: normal;
                        font-family: 'hsemibold';
                        line-height: 117%;
                    }

                    .formarginleft {
                        margin-left: 30px;
                    }
                }

                .darkparaupper {
                    color: #FFF;
                    font-size: 14px;
                    font-style: normal;
                    font-family: 'hsemibold';
                    line-height: 117%;
                    margin: 14px 0px;
                }
            }
        }

        .checkboxmain {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            margin-bottom: 15px;

            .clear {
                clear: both;
            }

            .checkBox {
                display: block;
                cursor: pointer;
                width: 20px;
                height: 20px;
                border: 3px solid rgba(255, 255, 255, 0);
                position: relative;
                overflow: hidden;
                box-shadow: 0px 0px 0px 2px #FFF;
            }

            .checkBox div {
                width: 60px;
                height: 60px;
                background-color: #FFF;
                top: -52px;
                left: -52px;
                position: absolute;
                transform: rotateZ(45deg);
                z-index: 100;
            }

            .checkBox input[type=checkbox]:checked+div {
                left: -10px;
                top: -10px;
            }

            .checkBox input[type=checkbox] {
                position: absolute;
                left: 50px;
                visibility: hidden;
            }

            .transition {
                transition: 300ms ease;
            }

            .checkboxpara {
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-family: 'hregular';
                line-height: 117%;
            }
        }

        .continuebutton {
            border: 1px solid #FFF;
            background: #000;
            box-shadow: 4px 4px 0px 0px #FFF;
            display: flex;
            padding: 15px 40px;
            justify-content: center;
            align-items: center;
            width: 100%;
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-family: 'hsemibold';
            line-height: 100%;
            text-transform: uppercase;
            margin-top: 25px;
        }

        .disablebutton {
            display: flex;
            padding: 15px 40px;
            justify-content: center;
            align-items: center;
            background: rgba(255, 255, 255, 0.05);
            color: #363636;
            font-size: 16px;
            font-style: normal;
            font-family: 'hsemibold';
            line-height: 100%;
            text-transform: uppercase;
            width: 100%;
            border: none;
            margin-top: 25px;
        }

        .twice-btns {
            display: flex;
            align-items: center;
            gap: 10px;

            button {
                border-radius: 4px;
                border: 1px solid #C1CBCE;
                background: transparent;
                height: 180px;
                width: 100%;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                transition: 0.3s linear;
                gap: 13px;
                padding: 20px;

                h6 {
                    color: var(--Light-Mode-Primary-Text-Color, #02171D);
                    text-align: center;
                    font-family: "hmedium";
                    font-size: 18px;
                    font-style: normal;
                    line-height: 100%;
                }

                &:hover {
                    border-radius: 4px;
                    background: #C8D4D8;
                }
            }
        }

        .buy-modal {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding-top: 30px;

            p {
                color: #000;
                font-size: 16px;
                font-style: normal;
                font-family: 'hregular';
                line-height: 140%;
                text-align: center;
                margin: 20px 0px 30px;

                span {
                    font-family: 'hsemibold';
                }
            }

            .btn-common {
                border-radius: 4px;
                background: #329879;
                display: flex;
                padding: 16px 14px 16px 19px;
                justify-content: center;
                align-items: center;
                color: #E2E9EC;
                text-align: center;
                font-family: "hmedium";
                border: none;
                width: 100%;
                font-size: 14px;
                font-style: normal;
                line-height: 120%;
            }

        }
    }
}


@media (max-width:991px) {
    .connectwallet-modal .modal-body .twice-btns {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
    }

    .connectwallet-modal .modal-body .twice-btns button {
        padding: 30px;
    }
}



.connect-btn {
    border-radius: 4px;
    background: #329879;
    display: flex;
    padding: 18px 22px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #e1e9ec;
    font-family: "hregular";
    font-size: 14px;
    font-style: normal;
    line-height: 100%;
    width: 100%;
    border: none;
    height: 42px;

    &:hover {
        background: #1e7e61;
        transition: 0.3s;
    }

    &:focus {
        background: #1e7e61;
        box-shadow: 0px 0px 10px 8px rgba(50, 152, 121, 0.5);
        transition: 0.3s;
    }
}

.dissconnect-btn {
    display: flex;
    padding: 18px 22px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: transparent;
    border: 1px solid #FC4F52;
    color: #FC4F52;
    font-family: "hmedium";
    font-size: 14px;
    font-style: normal;
    line-height: 100%;
    height: 42px;
}

.dvygdyvdv {
    margin-right: 15px;
}

.mobile-only-set {
    display: none;
}

@media (max-width:991px) {
    .mobile-only-set {
        margin: 0 auto;
        display: block;
        width: 100%;
        text-align: center;

        .connect-btn {
            max-width: 100%;
            width: 100%;
            margin: 0 auto;
        }

        .dissconnect-btn {
            max-width: 100%;
            width: 100%;
            margin: 0 auto;
        }
    }
}

.special-access {
    margin: 40px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;

    h6 {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-family: 'hregular';
        line-height: normal;
    }
}