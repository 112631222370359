.datatable {
    .main-heading {
        margin: 30px 0;

        h6 {
            color: #FFF;
            font-size: 22px;
            font-style: normal;
                 font-family: 'hsemibold';
            line-height: 24px;
            letter-spacing: 0.44px;
        }
    }

    .table-responsive {
        border: 1px solid #FFF;
        background: #000;

        .table {
            margin: 0;
        }

        th {
            color: #FFF;
            font-size: 14px;
            font-style: normal;
                 font-family: 'hsemibold';
            line-height: 24px;
            /* 171.429% */
            letter-spacing: 0.28px;
            vertical-align: middle !important;
            border: none;
            padding: 28px 15px;
            position: relative;
            white-space: nowrap;
            background-color: transparent;

        }

        td {
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-family: 'hregular';
            line-height: 24px;
            /* 171.429% */
            letter-spacing: 0.1px;
            vertical-align: middle !important;
            border-top: 1px solid #FFF;
            padding: 0;
            padding: 30px 15px;
            white-space: nowrap;
            background-color: transparent;

            .twice-text {
                h6 {
                    color: #FFF;
                    font-size: 12px;
                    font-style: normal;
                         font-family: 'hsemibold';
                    line-height: 21px;
                    /* 175% */
                    letter-spacing: 0.24px;
                }

                p {
                    color: #FFF;
                    font-size: 12px;
                    font-style: normal;
                    font-family: 'hregular';
                    line-height: 21px;
                    /* 175% */
                    letter-spacing: 0.24px;
                    margin-top: 2px;
                }
            }


            &:last-child {
                margin-left: auto;
            }

            .forend {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .common-btn {
                    padding: 13px 25px;
                    font-family: 'hsemibold';  
                    font-size: 16px;
                    line-height: 100%;
                }
            }
        }

    
.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px !important;
    // border-top: 1px solid #F2F2F2;
    border-radius: 0px 0px 4px 4px;
    background: #ECF5F9 !important;
    margin-top: 0 !important;
  
    .page-item.active .page-link {
      border-radius: 5px !important;
      box-shadow: none !important;
      background: #C1EBE2 !important;
      color: #329879 !important;
    }
  
    .left-f {
      h6 {
        color: #77868B;
        font-family: "hmedium";
        font-size: 12px;
        font-style: normal;
        line-height: 110%;
        letter-spacing: 0.1px;
      }
    }
  
    .right-f {
      .page-link:focus {
        box-shadow: none;
        background-color: unset;
      }
  
      .page-link:hover {
        box-shadow: none;
        background-color: unset;
      }
  
      .page-link {
        border: none;
        color: #869397;
        font-family: "f37";
        font-size: 15px;
        font-style: normal;
        line-height: 100%;
        background-color: transparent;
        padding: 6px 11px;
        border-radius: 5px;
  
        &.active {
          background: #C1EBE2;
          color: #329879;
        }
      }
  
      .page-item:first-child .page-link {
        color: #869397;
        font-family: "f37";
        font-size: 15px;
        font-style: normal;
        line-height: 100%;
      }
    }
  }
    }
    .dropdown{
        .dropdown-toggle{
            background-color: transparent;
            border: none;
            &::after{
                display: none;
            }
        }
        .dropdown-menu{
            border: 1px solid #FFF;
            background: #000;
            box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.05);
            padding: 0;
            a{
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-family: 'hregular';
                line-height: normal;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;
                padding: 14px 15px;
            }
        }
    }
}


.filter-btn {
    display: flex;
    flex-direction: column;
    width: 11px;
    margin-left: 10px;
}

.parent-tag {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}



.dsuygfdsyufgsdyuf .inner-textt button{
    background: transparent;
    border: 1px solid #fff;
    width: 100%;
    height: 50px;
    color: #fff;
}

@media (max-width:650px) {
    .dsuygfdsyufgsdyuf .twice-text{
        text-align: right;
    }
    .table-responsive {
        display: none;
    }

    .accmblview {
        display: block !important;
        border: 1px solid #FFF;
        background: #000;
        backdrop-filter: blur(3px);

        .main-twice-text{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 25px 28px;
            padding-right: 61px;
            
            .accmblviewhead {
                color: #FFF;
                font-size: 14px;
                     font-family: 'hsemibold';
                line-height: 24px;
                letter-spacing: 0.28px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
             
    
                .arrowsacc {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        

        .accordion {
            border: none !important;
            background: transparent !important;
            box-shadow: none !important;
            outline: none !important;
            border-radius: none !important;

            .accordion-button::after {
                background: url("../../../../assets/accarrowdown.svg");
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                width: 8.571px;
                height: 5.143px;
                position: absolute;
                top: 28px;
                right: 25px;
            }

            .accordion-item {
                border: none !important;
                background: transparent !important;
                box-shadow: none !important;
                outline: none !important;
                border-radius: none !important;

                .accordion-header {
                    border: none !important;
                    background: transparent !important;
                    box-shadow: none !important;
                    outline: none !important;
                    border-radius: none !important;


                    .accordion-button {
                        border-top: 1px solid #FFF;
                        border-bottom: 1px solid #FFF;
                        background: var(--login-bg);
                        padding: 22px 25px;
                        padding-right: 61px;

                        &:focus {
                            box-shadow: none;
                        }

                        .accheadermain {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 30px;
                            width: 100%;

                            .accmainimage {
                                width: 30px;
                                height: 30px;
                                border-radius: 60px;
                                background: rgba(0, 0, 0, 0.04);
                                object-fit: cover;
                                object-position: center;
                                display: flex;
                                align-items: center;

                                .accinnerimg {
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 60px;
                                    background: rgba(0, 0, 0, 0.04);
                                    object-fit: cover;
                                    object-position: center;
                                }
                            }

                            .acctext {
                                color: #FFF;
                                font-size: 14px;
                                font-style: normal;
                                     font-family: 'hsemibold';
                                line-height: 100%;
                            }
                        }
                    }
                }

                .accordion-body {
                    border: none !important;
                    background: transparent !important;
                    box-shadow: none !important;
                    outline: none !important;
                    border-radius: none !important;
                    padding: 0px 25px;

                    .acctexts {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 20px 0px;
                        border-bottom: 1px solid #1C1C1C;

                        &:last-child {
                            margin-bottom: 0px;
                        }

                        .textleft {
                            color: #FFF;
                            font-size: 14px;
                            font-style: normal;
                                 font-family: 'hsemibold';
                            line-height: 100%;
                            letter-spacing: 0.28px;
                            text-transform: uppercase;
                        }

                        .textright {
                            color: #FFF;
                            font-size: 14px;
                            font-style: normal;
                                 font-family: 'hsemibold';
                            line-height: 21px;
                            letter-spacing: 0.28px;
                        }

                        .brdrbtn {
                            border: 1px solid #FFF;
                            background: #000;
                            box-shadow: 2px 2px 0px 0px #FFF;
                            display: flex;
                            padding: 7px 15px;
                            justify-content: center;
                            align-items: center;
                            color: #FFF;
                            font-size: 12px;
                            font-style: normal;
                                 font-family: 'hsemibold';
                            line-height: 120%;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
        .twice-text {
            h6 {
                color: #FFF;
                font-size: 12px;
                font-style: normal;
                     font-family: 'hsemibold';
                line-height: 21px;
                /* 175% */
                letter-spacing: 0.24px;
            }

            p {
                color: #FFF;
                font-size: 12px;
                font-style: normal;
                font-family: 'hregular';
                line-height: 21px;
                /* 175% */
                letter-spacing: 0.24px;
                margin-top: 2px;
            }
        }
        .view-btn{
            border: 1px solid #FFF;
            background-color: transparent;
            padding: 13px;
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-family: 'hregular';
            line-height: 100%; /* 14px */
            width: 100%;
        }
    }

    .accmblview .accordion .accordion-item .accordion-header .accordion-button {
        box-shadow: none !important;
    }

    .footer-content {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        // border-top: 1px solid #F2F2F2;
        background-color: transparent;

        .left-f {
            h6 {
                font-style: normal;
                  font-family: 'hmedium';
                font-size: 14px;
                line-height: 100%;
                color: #A0A1A1;
            }
        }

        .right-f {
            .page-link:focus {
                box-shadow: none;
                background-color: unset;
            }

            .page-link:hover {
                box-shadow: none;
                background-color: unset;
            }

            .page-link {
                border: none;
                color: #A2A4A6;
                font-size: 15px;
                font-style: normal;
                     font-family: 'hsemibold';
                line-height: 100%;
                /* 15px */
                background-color: transparent;

                &.active {
                    background: #fff;
                    color: #000;
                }
            }

            .page-item:first-child .page-link {
                font-style: normal;
                  font-family: 'hmedium';
                font-size: 15px;
                line-height: 100%;
                color: #A0A1A1;
            }
        }
    }

    .dashboardetail .auctiondetailhead {
        font-size: 20px;
    }
}


.page-item.active {
    .page-link {
        border-radius: 5px !important;
        background: #000 !important;
        color: #FFF !important;
        box-shadow: 0px -4px 0px 0px rgba(255, 255, 255, 0.20) inset !important;
    }
}

.active-pag{
    background: #C1EBE2 !important;
    color: #329879 !important;
}