.allinstitution-agents {
  .main-heading .rightside-content {
    width: 625px;
  }
  .viewdetail-tabs {
    opacity: 0;
  }
  .main-heading111 .rightside-content .dropdown {
    max-width: 0 !important;
    width: 0 !important;
  }
  .bottom-cards .single-card {
    flex: 0 0 32.4% !important;
  }
}

@media (max-width: 600px) {
  .allinstitution-agents .bottom-cards .single-card {
    flex: 0 0 48% !important;
  }
  .main-heading .rightside-content {
    width: 100% !important;
  }
  .newbtnsets {
    flex-direction: column;
    width: 100%;
  }
  .newbtnsets .dropdown {
    width: 100%;
    max-width: 100%;
    .dropdown-toggle {
      width: 100% ;
      justify-content: space-between;
    }
  }
  .salesround .main-heading .parent-btns{
    width: 100%;
  }
}
