.selfviewdetail {
  padding: 60px 0;

  .main-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    .leftheadings {
      display: flex;
      align-items: center;
      gap: 14px;

      .btn-white {
        display: flex;
        height: 46px;
        padding: 15px 22px 15px 12px;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        background: #ecf5f9;
        color: #000;
        font-family: "hmedium";
        font-size: 14px;
        font-style: normal;
        line-height: 100%;
        border: none;
        text-transform: uppercase;
      }

      .innermain {
        .selfpara {
          color: var(--Secondary-Text-Color, #77868b);
          -webkit-text-stroke-width: 0.1px;
          -webkit-text-stroke-color: #329879;
          font-family: "f37";
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 110%;
          margin-bottom: 10px;
          text-transform: uppercase;
        }

        .twice-flex {
          display: flex;
          align-items: center;
          gap: 10px;

          .tokenimg {
            display: flex;
            width: 28px;
            height: 28px;
            justify-content: center;
            align-items: center;
            border-radius: 60px;
            object-fit: cover;
            object-position: center;
            background: #d1dbdf;

            .innertokenimg {
              width: 100%;
              height: 100%;
              border-radius: 60px;
              object-fit: cover;
              object-position: center;
            }
          }

          .twice-texts {
            display: flex;
            align-items: center;
            gap: 10px;

            .tokenname {
              color: var(--Primary-Text-Color, #02171d);
              font-family: "f37";
              font-size: 24px;
              font-style: normal;
              line-height: 24px;
              letter-spacing: 0.48px;
              text-transform: uppercase;
            }

            .ended {
              color: #d80027;
              background: rgba(216, 0, 39, 0.16);
            }

            .draft {
              color: #0052b4;
              background: rgba(0, 82, 180, 0.16);
            }

            .upcoming {
              color: #f7931e;
              background: rgba(247, 147, 30, 0.16);
            }

            .live {
              color: #009245;
              background: rgba(0, 146, 69, 0.16);
            }

            .statusmain {
              display: flex;
              padding: 0px 10px;
              justify-content: center;
              align-items: center;
              border-radius: 13px;
              font-family: "hmedium";
              font-size: 16px;
              font-style: normal;
              line-height: 110%;
              letter-spacing: 0.24px;
              text-transform: capitalize;
              max-width: fit-content;
              height: 24px;
            }
          }
        }
      }

      h6 {
        color: var(--Primary-Text-Color, #02171d);
        font-family: "f37";
        font-size: 22px;
        font-style: normal;
        line-height: 24px;
        letter-spacing: 0.44px;
        text-transform: uppercase;
      }
    }

    .inputsmains {
      width: 334px;
      position: relative;

      .dropdown {
        position: absolute;
        top: 0;
        left: 0;

        .btn {
          border: none;
          border-right: 1px solid #d0dee4 !important;
          display: flex;
          padding: 0px 15px;
          height: 34px;
          color: var(--Primary-Text-Color, #02171d);
          font-family: "hregular";
          font-size: 14px;
          font-style: normal;
          line-height: 100%;
          align-items: center;
          gap: 7px;
          background-color: #d1dbdf;

          &::after {
            display: none;
          }
        }

        .dropdown-menu {
          border-radius: 4px;
          background: #d1dbdf;
          padding: 0;
          border: 1px solid #c1cbce;

          .dropdown-item {
            margin: 0;
            padding: 10px;
            color: #02171d;
            font-family: "hregular";
            font-size: 14px;
            font-style: normal;
            line-height: 100%;
            border-bottom: 1px solid #c1cbce;

            &:last-child {
              border-bottom: none;
            }
          }
        }
      }

      .searchimg {
        position: absolute;
        top: 8px;
        right: 15px;
      }

      .innerinput {
        border-radius: 4px;
        border: 1.5px solid #d0dee4;
        background: #d1dbdf;
        height: 34px;
        padding: 12px 15px;
        color: #77868b;
        font-family: "hregular";
        font-size: 14px;
        font-style: normal;
        width: 100%;
        outline: none;
        border: none;
        line-height: 100%;
        padding-left: 110px !important;
      }
    }

    .publishmain {
      display: flex;
      align-items: center;
      gap: 10px;

      .publishpara {
        color: #000;
        font-family: "hmedium";
        font-size: 12px;
        font-style: normal;
        line-height: normal;
        text-transform: uppercase;
      }

      .switch {
        font-size: 17px;
        position: relative;
        display: inline-block;
        width: 36px;
        height: 18px;
        border: none !important;
        outline: none !important;
      }

      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
        border: none !important;
        outline: none !important;
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #d1dbdf;
        transition: 0.4s;
        border-radius: 30px;
        border: none !important;
        outline: none !important;
      }

      .slider:before {
        position: absolute;
        content: "";
        width: 14px;
        height: 14px;
        border-radius: 20px;
        left: 2px;
        bottom: 2px;
        background-color: #0a2931;
        transition: 0.4s;
        border: none !important;
        outline: none !important;
      }

      input:checked + .slider {
        background-color: #0a2931;
        border: none !important;
        outline: none !important;
      }

      input:focus + .slider {
        box-shadow: 0 0 1px #0a2931;
        border: none !important;
        outline: none !important;
      }

      input:checked + .slider:before {
        transform: translateX(1em);
        background-color: #d1dbdf;
        border: none !important;
        outline: none !important;
      }
    }
  }

  .bottom-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 12px;

    .single-card {
      border-radius: 4px;
      background: #ecf5f9;
      box-shadow: none;
      min-height: 135px;
      padding: 20px;
      position: relative;

      .cardinner {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 15px;
        margin-bottom: 25px;

        p {
          margin-bottom: 10px;
          color: #77868b;
          font-family: "hregular";
          font-size: 14px;
          font-style: normal;
          line-height: 120%;
        }

        h6 {
          color: #021c24;
          font-family: "f37";
          font-size: 20px;
          font-style: normal;
          line-height: 120%;
          letter-spacing: -0.2px;
          display: flex;
          align-items: center;
          gap: 10px;

          .redpara {
            color: #fc4f52;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            margin: 0;
            letter-spacing: -0.14px;
          }

          .greenpara {
            color: #329879;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.14px;
            margin: 0;
          }
        }
      }

      .bottom-text {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        margin-top: 10px;
        flex-wrap: wrap;

        .uni-text {
          opacity: 1;
          display: flex;
          align-items: center;
          gap: 5px;
          color: #021c24;
          font-family: "hregular";
          font-size: 14px;
          font-style: normal;
          line-height: 120%;
          letter-spacing: -0.14px;
          margin: 0;

          span {
            background-color: #c1cbce;
            border-radius: 4px;
            width: 3px;
            height: 14px;
            border-radius: 4px;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }

  .parent-new-card {
    border-radius: 12px;
    border: 1px solid #e6e6e6;
    background: #ecf5f9;
    box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
    margin: 12px 0;
    padding: 26px 30px 30px 30px;

    .detailtabs .nav-item.show .nav-link,
    .detailtabs .nav-link.active {
      color: #329879 !important;
      border-bottom: 1px solid #329879 !important;
    }

    .detailtabs {
      display: flex;
      align-items: center;
      gap: 30px;
      margin-bottom: 30px;

      .nav-item {
        .nav-link {
          color: #01141a;
          font-family: "hregular";
          font-size: 16px;
          font-style: normal;
          background-color: transparent;
          border: none;
          padding: 0;
          line-height: 100%;
          padding-bottom: 17px;
          border-bottom: 1px solid transparent;
        }
      }
    }

    .bottom-detail {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 30px 10px;

      .inner-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;

        p {
          color: var(--Light-Mode-Secondary-Text-Color, #77868b);
          font-family: "hregular";
          font-size: 14px;
          font-style: normal;
          line-height: 120%;
        }

        h6 {
          display: flex;
          align-items: center;
          gap: 8px;
          color: #000;
          font-family: "f37";
          font-size: 16px;
          font-style: normal;
          line-height: 120%;
        }

        a {
          display: flex;
          align-items: center;
          gap: 8px;
          color: #000;
          font-family: "f37";
          font-size: 16px;
          font-style: normal;
          line-height: 120%;
          text-decoration: underline !important;
        }

        .socialicons {
          display: flex;
          align-items: center;
          gap: 5px;

          .innericon {
            border-radius: 100px;
            display: flex;
            padding: 5px;
            align-items: center;
            background: rgba(79, 92, 97, 0.1);
          }
        }
      }
    }

    .roundmainpara {
      margin-top: 20px;
      margin-bottom: 8px;
      color: var(--Light-Mode-Secondary-Text-Color, #77868b);
      font-family: "hregular";
      font-size: 14px;
      font-style: normal;
      line-height: 120%;
    }

    .roundmain {
      border-radius: 4px;
      background: var(--Background-Color, #e2e9ec);
      padding: 20px 10px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .innerinputmain {
        display: grid;
        grid-template-columns: 0.5fr 1fr 1fr;
        gap: 15px;

        .innerinput {
          display: flex;
          flex-direction: column;
          gap: 12px;

          .inputpara {
            color: var(--Primary-Text-Color, #02171d);
            font-family: "hmedium";
            font-size: 14px;
            font-style: normal;
            line-height: 120%;
          }

          .readinput {
            border-radius: 4px;
            border: 1px solid #eee;
            background: #d1dbdf;
            height: 46px;
            padding: 16px 18px;
            color: #02171d;
            font-family: "hregular";
            font-size: 14px;
            font-style: normal;
            line-height: 100%;
            border: none;
            outline: none;

            &::placeholder {
              color: #02171d;
              font-family: "hregular";
              font-size: 14px;
              font-style: normal;
              line-height: 100%;
            }
          }
        }
      }
    }
  }

  .valuemaindiv {
    position: absolute;
    right: 20px;
    bottom: 20px;

    .dropdown {
      .btn {
        padding: 0px;
        margin: 0px;
        background: transparent;
        border: none;
        outline: none;

        &::after {
          display: none !important;
        }
      }

      .dropdown-menu {
        padding: 0px;
        margin: 0px;
        border-radius: 4px;
        background: #ecf5f9;
        box-shadow: none;
        border: 1px solid #c1cbce;
        width: 208px;
        overflow: hidden;

        .teamsdrops {
          .innerteamdrop {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px 18px;
            border-bottom: 1px solid #c1cbce;
            background: #ecf5f9;

            &:last-child {
              border: none !important;
            }

            .teamdroppara {
              color: #77868b;
              font-family: "hregular";
              font-size: 14px;
              font-style: normal;
              line-height: 120%;
              margin: 0;
            }

            .teamdrophead {
              color: var(--Black, #000);
              font-family: "hregular";
              font-size: 14px;
              font-style: normal;
              line-height: 120%;
            }
          }
        }
      }
    }

    .valuemain {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;

      .valueline {
        border-radius: 4px;
        background: #dfdfdf;
        width: 3px;
        height: 14px;
        display: block;
      }

      .valuepara {
        color: var(--Black, #000);
        font-size: 14px;
        font-style: normal;
        font-family: "hregular";
        line-height: 120%;
        letter-spacing: -0.14px;

        .darktext {
          color: var(--Black, #000);
          font-size: 14px;
          font-style: normal;
          font-family: "hmedium";
          line-height: 120%;
          letter-spacing: -0.14px;
          margin-right: 5px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .selfviewdetail .main-heading {
    flex-direction: column;
    gap: 15px;
  }
  .selfviewdetail .main-heading .leftheadings {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }
  .selfviewdetail .main-heading .publishmain {
    justify-content: flex-start;
    width: 100%;
  }
  .selfviewdetail .bottom-cards {
    grid-template-columns: 1fr;
  }
  .selfviewdetail .parent-new-card .bottom-detail {
    grid-template-columns: 1fr;
  }
  .selfviewdetail .parent-new-card .roundmain .innerinputmain {
    grid-template-columns: 1fr !important;
  }
  .selfviewdetail .main-heading .inputsmains {
    width: 100%;
  }
}
