.forgotpassword-wrapper{
    .navbar-expand-lg .navbar-collapse{
        display: none !important;
    }
    .d-noneonmobile{
        display: none !important;
    }
}

.forgot-password {
    padding-top: 56px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .parent-card {
        border-radius: 15px;
        border: 1px solid #F2F2F2;
        background: #FFF;
        box-shadow: 0px -8px 0px 0px rgba(0, 0, 0, 0.06) inset;
        max-width: 542px;
        width: 100%;
        padding: 35px 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0 auto;

        .upper-logo {
            text-align: center;
            margin-bottom: 50px;
        }

        .bottom-content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;

            h6 {
                margin-top: 50px;
                margin-bottom: 15px;
                color: #000;
                font-size: 22px;
                font-style: normal;
                     font-family: 'hsemibold';
                line-height: 110%;
                /* 24.2px */
                text-transform: uppercase;
            }

            p {
                color: #000;
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-family: 'hregular';
                line-height: 130%;
                /* 18.2px */
                margin-bottom: 40px;
            }

            .option-field {
                width: 100%;
            }

            .btn-send {
                margin-top: 40px;
                border-radius: 15px;
                background: #4CEAD7;
                box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.20) inset;
                padding: 15px;
                width: 100%;
                color: #000;
                font-size: 16px;
                font-style: normal;
                     font-family: 'hsemibold';
                line-height: 100%;
                /* 16px */
                text-transform: uppercase;
            }
        }
    }
    .btn-back{
        border: 1px solid rgba(255, 255, 255, 0.10);
        background: rgba(255, 255, 255, 0.05);
        padding: 15px 10px;
        display: flex;
        align-items: center;
        gap: 6px;
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-family: 'hregular';
        line-height: normal;
    }
    .option-field{
        width: 100%;
        position: relative;
        .password-icon{
            position: absolute;
            top: 13px;
            right: 16px;
        }
        .iferrorinput{
            border: 1px solid #FE0000 !important;
            color: #FE0000 !important;
        }
        .iferrorinputtext{
            color: #FE0000 !important;
        }
    }
    .listing-item{
        width: 100%;
        margin-bottom: 40px;
        h6{
            color: #000;
            font-size: 14px;
            font-style: normal;
                 font-family: 'hsemibold';
            line-height: normal;
            margin-bottom: 6px;
        }
        ul{
            li{
                color: #000;
                font-size: 14px;
                font-style: normal;
                     font-family: 'hsemibold';
                line-height: normal;
                margin-bottom: 5px;
                list-style-type: disc;
                list-style-position: inside;
            }
        }
    }
}