.allprojectmain {
    min-height: 100vh;
    padding-top: 35px;

    .allprojecttop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 28px;

        .leftheadings {
            .selfpara {
                color: var(--Secondary-Text-Color, #77868B);
                -webkit-text-stroke-width: 0.1px;
                -webkit-text-stroke-color: #329879;
                font-family: "f37";
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 110%;
                text-transform: uppercase;
                margin-bottom: 10px;
            }

            .mainhead {
                color: var(--Primary-Text-Color, #02171D);
                font-family: "f37";
                font-size: 30px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0.6px;
                text-transform: uppercase;
            }
        }

        .rightmain {
            display: flex;
            align-items: center;
            gap: 15px;

            .statusdropdown {
                .btn {
                    border-radius: 4px;
                    background: #D1DBDF;
                    display: flex;
                    height: 34px;
                    padding: 11px 14px;
                    align-items: center;
                    gap: 12px;
                    color: #02171D;
                    text-align: center;
                    font-family: "hregular";
                    font-size: 12px;
                    font-style: normal;
                    line-height: 100%;
                    border: none;

                    &::after {
                        display: none;
                    }
                }

                .dropdown-menu {
                    border-radius: 4px;
                    background: #D1DBDF;
                    padding: 0;
                    border: 1px solid #C1CBCE;

                    .dropdown-item {
                        margin: 0;
                        padding: 10px;
                        color: #02171D;
                        font-family: "hregular";
                        font-size: 14px;
                        font-style: normal;
                        line-height: 100%;
                        border-bottom: 1px solid #C1CBCE;

                        &:last-child {
                            border-bottom: none;
                        }
                    }
                }
            }

            .inputmain {
                width: 380px;
                position: relative;

                .searchsvg{
                    position: absolute;
                    top: 8px;
                    right: 15px;
                }

                .innerinput {
                    height: 34px;
                    padding: 12px 15px;
                    border-radius: 4px;
                    background: #D1DBDF;
                    color: #000;
                    font-family: "hregular";
                    font-size: 14px;
                    font-style: normal;
                    line-height: 100%;
                    border: none;
                    outline: none;
                    width: 100%;
                    padding-right: 50px !important;

                    &::placeholder{
                        color: #96A6AC;
                        font-family: "hregular";
                        font-size: 14px;
                        font-style: normal;
                        line-height: 100%;
                    }
                }
            }
        }
    }
}