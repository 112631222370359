.stats-section {
    .main-cards {
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 12px;

        .single-card {
            border-radius: 12px;
            border: 1px solid #E6E6E6;
            background: #FFF;
            box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
            padding: 20px;
            position: relative;
            z-index: 9;

            .inner-parent {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 15px;
                margin-bottom: 25px;

                .right-stats {
                    p {
                        color: var(--Black, #000);
                        font-size: 14px;
                        font-style: normal;
                        font-family: 'hregular';
                        line-height: 120%;
                        text-transform: uppercase;
                        opacity: 0.6;
                        margin-bottom: 6px;
                    }

                    h6 {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        color: var(--Black, #000);
                        font-size: 18px;
                        font-style: normal;
                        font-family: 'hsemibold';
                        line-height: 120%;
                        letter-spacing: -0.18px;

                        span {

                            font-size: 14px;
                            font-style: normal;
                            font-family: 'hregular';
                            line-height: 120%;
                            letter-spacing: -0.14px;

                            &.green {
                                color: #22C154 !important;
                            }
                        }
                    }
                }
            }

            .bottom-value {
                p {
                    color: var(--Black, #000);
                    font-size: 14px;
                    font-style: normal;
                    font-family: 'hregular';
                    line-height: 120%;
                    letter-spacing: -0.14px;
                    text-transform: uppercase;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 5px;

                    span {
                        display: flex;
                        border-radius: 4px;
                        background: #DFDFDF;
                        width: 3px;
                        height: 14px;
                    }
                }
            }

            .cardinsidedrop {
                position: absolute;
                bottom: 20px;
                right: 20px;
                max-width: unset;
                width: auto;

                button {
                    background-color: transparent;
                    border: none;
                    &::after{
                        display: none;
                    }
                    &[aria-expanded= "true"]{
                        svg{
                            transform: rotate(180deg);
                        }
                    }
                }

                .dropdown-menu {
                    border-radius: 12px;
                    border: 1px solid #E6E6E6;
                    background: #FFF;
                    box-shadow: 0px 14px 34px 0px rgba(0, 0, 0, 0.04);
                    padding: 0;
                    min-width: 208px;
                    left: unset !important;
                    right: 0 !important;

                    .text {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border-bottom: 1px solid #F2F2F2;
                        background: #FFF;
                        padding: 10px 18px;
                        p{
                            color: #777;
                            font-size: 14px;
                            font-style: normal;
                            font-family: 'hregular';
                            line-height: normal;
                        }
                        h6{
                            color: var(--Black, #000);
                            font-size: 14px;
                            font-style: normal;
                            font-family: 'hmedium'; 
                            line-height: normal;
                        }

                    }
                }
            }

        }
    }
}

@media (max-width:600px) {
    .stats-section .main-cards {
        grid-template-columns: auto;
    }
}