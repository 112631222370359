.allreferrermain {
  min-height: 100vh;
  padding-top: 35px;

  .innerreferrerheading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 28px;

    .selfhead {
      color: var(--Secondary-Text-Color, #77868b);
      -webkit-text-stroke-width: 0.10014298558235168;
      -webkit-text-stroke-color: #329879;
      font-family: "f37";
      font-size: 12px;
      font-style: normal;
      line-height: 110%;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    .refferhead {
      color: var(--Primary-Text-Color, #02171d);
      font-family: "f37";
      font-size: 30px;
      font-style: normal;
      line-height: 24px;
      letter-spacing: 0.6px;
      text-transform: uppercase;
    }

    .rightside-content {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;

      .whitnewbtn {
        border-radius: 4px;
        background: #d1dbdf;
        display: flex;
        height: 34px;
        padding: 8px 12px;
        align-items: center;
        gap: 12px;
        color: #02171d;
        font-family: "hmedium";
        font-size: 12px;
        font-style: normal;
        line-height: 120%;
        border: none;
        box-shadow: none !important;
        white-space: nowrap;
      }

      .custom-option-field {
        width: 100%;

        .positionset {
          position: absolute;
          top: 0;
          left: 0;

          .btn {
            border-top-right-radius: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 7px;
            outline: none;
            border-bottom-right-radius: 0px;
            border: none;
            width: unset !important;
            border-right: 1px solid #d0dee4 !important;
            background: #d1dbdf;
            color: var(--Primary-Text-Color, #02171d);
            font-family: "hregular";
            font-size: 14px;
            font-style: normal;
            line-height: 100%;

            &:hover {
              background: transparent !important;
              color: #02171d;
            }
          }
        }
      }

      .dropdown {
        max-width: 110px;
        width: 100%;

        .dropdown-toggle {
          padding: 15px;
          width: 100%;
          box-shadow: none;
          height: 34px;

          &[aria-expanded="true"] {
            box-shadow: none;
          }

          &::after {
            display: none;
          }
        }

        .dropdown-menu {
          border-radius: 4px;
          background: #d1dbdf;
          border: 1px solid #c1cbce;
          padding: 0;
          box-shadow: none !important;
          overflow: hidden;

          a {
            padding: 12px 15px;
            color: #02171d;
            font-family: "hregular";
            font-size: 12px;
            font-style: normal;
            line-height: 100%;
            border-bottom: 1px solid #c1cbce;
            // &:last-child{
            //     border-bottom: 1px solid transparent;
            // }
            background: transparent;
          }
        }
      }
    }

    .multi-items {
      max-width: 592px;
      width: 100%;

      .set-twice-text {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 15px;
        margin-bottom: 15px;

        h6 {
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-family: "hregular";
          line-height: normal;
        }
      }
    }
  }
}

.viewdetailmain {
  padding: 35px 0;

  .bottom-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 12px;
    margin-bottom: 25px;

    .single-card {
      border-radius: 4px;
      background: #ecf5f9;
      padding: 20px 20px 40px 20px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 15px;

      .single-card-texts {
        p {
          color: #77868b;
          font-family: "hregular";
          margin-bottom: 6px;
          font-size: 14px;
          font-style: normal;
          line-height: 120%;
        }

        h6 {
          color: #021c24;
          font-family: "f37";
          font-size: 18px;
          font-style: normal;
          line-height: 120%;
          letter-spacing: -0.18px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;

          .greentext {
            color: #329879;
            font-size: 14px;
            font-style: normal;
            font-family: "hregular";
            line-height: 120%;
            letter-spacing: -0.14px;
          }
        }
      }
    }
  }

  .parent-new-card {
    border-radius: 4px;
    background: #ecf5f9;
    padding: 30px;
    margin-bottom: 25px;

    .main-headingg {
      h6 {
        color: #000;
        font-family: "f37";
        font-size: 20px;
        font-style: normal;
        line-height: 24px;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        margin-bottom: 30px;
      }
    }

    .bottom-cards1 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;

      .inner-text {
        p {
          color: #77868b;
          font-family: "f37";
          font-size: 14px;
          font-style: normal;
          line-height: 120%;
          letter-spacing: 0.28px;
          margin-bottom: 8px;
        }

        h6 {
          color: #02171d;
          font-family: "hmedium";
          font-size: 16px;
          font-style: normal;
          line-height: 110%;
          letter-spacing: 0.32px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 6px;
        }
      }
    }
  }

  .innerreferrerheading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 28px;

    .selfhead {
      color: var(--Secondary-Text-Color, #77868b);
      -webkit-text-stroke-width: 0.10014298558235168;
      -webkit-text-stroke-color: #329879;
      font-family: "f37";
      font-size: 12px;
      font-style: normal;
      line-height: 110%;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    .refferhead {
      color: var(--Primary-Text-Color, #02171d);
      font-family: "f37";
      font-size: 30px;
      font-style: normal;
      line-height: 24px;
      letter-spacing: 0.6px;
      text-transform: uppercase;
    }

    .rightside-content {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;

      .whitnewbtn {
        border-radius: 4px;
        background: #d1dbdf;
        display: flex;
        height: 34px;
        padding: 8px 12px;
        align-items: center;
        gap: 12px;
        color: #02171d;
        font-family: "hmedium";
        font-size: 12px;
        font-style: normal;
        line-height: 120%;
        border: none;
        box-shadow: none !important;
        white-space: nowrap;
      }

      .custom-option-field {
        width: 100%;

        .positionset {
          position: absolute;
          top: 0;
          left: 0;

          .btn {
            border-top-right-radius: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 7px;
            outline: none;
            border-bottom-right-radius: 0px;
            border: none;
            width: unset !important;
            border-right: 1px solid #d0dee4 !important;
            background: #d1dbdf;
            color: var(--Primary-Text-Color, #02171d);
            font-family: "hregular";
            font-size: 14px;
            font-style: normal;
            line-height: 100%;

            &:hover {
              background: transparent !important;
              color: #02171d;
            }
          }
        }
      }

      .dropdown {
        max-width: 110px;
        width: 100%;

        .dropdown-toggle {
          padding: 15px;
          width: 100%;
          box-shadow: none;
          height: 34px;

          &[aria-expanded="true"] {
            box-shadow: none;
          }

          &::after {
            display: none;
          }
        }

        .dropdown-menu {
          border-radius: 4px;
          background: #d1dbdf;
          border: 1px solid #c1cbce;
          padding: 0;
          box-shadow: none !important;
          overflow: hidden;

          a {
            padding: 12px 15px;
            color: #02171d;
            font-family: "hregular";
            font-size: 12px;
            font-style: normal;
            line-height: 100%;
            border-bottom: 1px solid #c1cbce;
            // &:last-child{
            //     border-bottom: 1px solid transparent;
            // }
            background: transparent;
          }
        }
      }
    }

    .multi-items {
      max-width: 592px;
      width: 100%;

      .set-twice-text {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 15px;
        margin-bottom: 15px;

        h6 {
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-family: "hregular";
          line-height: normal;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .allreferrermain .innerreferrerheading {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
  }
  .allreferrermain .innerreferrerheading .rightside-content {
    flex-direction: column;
    width: 100%;
  }
  .allreferrermain .innerreferrerheading .multi-items {
    max-width: 100%;
  }
  .allreferrermain .innerreferrerheading .rightside-content .whitnewbtn {
    width: 100%;
  }
  .viewdetailmain .bottom-cards {
    grid-template-columns: 1fr;
  }
  .viewdetailmain .parent-new-card .bottom-cards1 {
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .viewdetailmain .innerreferrerheading {
    flex-direction: column;
    gap: 20px;
  }
  .viewdetailmain .innerreferrerheading .rightside-content {
    flex-direction: column;
    width: 100%;
  }
  .viewdetailmain .innerreferrerheading .multi-items {
    max-width: 100%;
  }
  .viewdetailmain .innerreferrerheading .rightside-content .whitnewbtn {
    width: 100%;
  }
}
