.salesround {
  padding: 60px 0;
  min-height: 100vh;

  .main-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .parent-btns {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 15px;
    }

    h6 {
      color: #021c24;
      font-family: "f37";
      font-size: 24px;
      font-style: normal;
      line-height: 24px;
      letter-spacing: 0.48px;
      text-transform: capitalize;
    }

    button {
      border-radius: 4px;
      background: #d1dbdf;
      display: flex;
      height: 40px;
      padding: 10px 12px;
      align-items: center;
      gap: 12px;
      color: #02171d;
      font-family: "hmedium";
      font-size: 12px;
      font-style: normal;
      line-height: 120%;
      border: none;
      box-shadow: none !important;
      width: 100%;
      justify-content: center;
    }

    .round-setting {
      border: none;
      color: #02171d;
      font-family: "hregular";
      font-size: 16px;
      font-style: normal;
      line-height: 110%;
      letter-spacing: 0.1px;
      display: flex;
      white-space: nowrap;
      align-items: center;
      gap: 10px;
      background-color: transparent;
    }
  }
}

.ysvtvstyvtiivtyvs {
  display: none;
}

.scuhvscycvst {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
}

.option-field {
  position: relative;

  .calendar-icon {
    position: absolute;
    top: 40px;
    right: 20px;
    cursor: pointer;
    pointer-events: none;
    background: #d1dbdf;
  }
}

.ijijijijij {
  // pointer-events: none;
  opacity: 0.5;
}

.material-textfield {
  margin-bottom: 14px !important;
}

.active-pag {
  background: #c1ebe2 !important;
  color: #329879 !important;
}

.ysvtvstyvtiivtyvs {
  opacity: 0 !important;
  width: 0 !important;
  height: 0 !important;
  visibility: hidden !important;

  td {
    opacity: 0 !important;
    width: 0 !important;
    height: 0 !important;
    visibility: hidden !important;
    padding: 0 !important;
  }
}

.add-mega-modal .option-field h5 {
  color: #000;
  margin-bottom: 18px;
  font-size: 12px;
}

.add-mega-modal .option-field {
  margin-bottom: 18px !important;
}

.add-mega-modal .dropdown {
  width: 100%;
  margin-bottom: 18px;

  .dropdown-toggle {
    outline: none;
    transition: 0.1s ease-out;
    border-radius: 4px;
    background: #d1dbdf;
    background: var(--Text-Field-Background, #d1dbdf);
    padding: 10px 18px;
    width: 100%;
    box-shadow: none !important;
    color: #000;
    font-family: "hregular";
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .projectmain {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      .projectimg {
        width: 30px;
      }
    }

    &::after {
      display: none;
    }
  }

  .dropdown-menu {
    border-radius: 4px;
    background: #d1dbdf;
    border: 1px solid #c1cbce;
    width: 100%;
    padding: 0;
    overflow: hidden;

    a {
      padding: 12px 15px;
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-family: "hregular";
      line-height: normal;
      border-bottom: 1px solid #c1cbce;
      // &:last-child{
      //     border-bottom: 1px solid transparent;
      // }
      background: transparent;

      .projectmain {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        .projectimg {
          width: 30px;
        }
      }
    }
  }
}

.add-mega-modal .mainproject {
  border-radius: 4px;
  background: #d1dbdf;
  display: flex;
  height: 46px;
  padding: 0px 12px;
  align-items: center;
  gap: 8px;
  .projectmainimg {
    width: 24px;
    height: 24px;
    border-radius: 60px;
    object-fit: cover;
    object-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    .innerprojectimg {
      width: 100%;
      height: 100%;
      border-radius: 60px;
      object-fit: cover;
      object-position: center;
    }
  }
  .projectpara {
    color: var(--Black, #000);
    font-size: 14px;
    font-style: normal;
    font-family: "hregular";
    line-height: 100%;
  }
}

@media (max-width: 991px) {
  .salesround .main-heading button {
    padding: 13px 15px;
    font-size: 14px;
  }

  .salesround .main-heading h6 {
    font-size: 18px;
  }
}

.opacc {
  opacity: 0.4;
  pointer-events: none;
}

.cdvtdvtvtvdcvtdvv .optionListContainer {
  position: relative !important;
}

.twice-inputssss {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 18px !important;

  .option-field {
    flex: 0 0 49%;
    margin-bottom: 0 !important;

    &:last-child {
      flex: 0 0 49%;
    }
  }
}

.add-mega-modal .twice-inputssss .option-field {
  margin-bottom: 0 !important;
}

.mybtnsforinput {
  border-radius: 0px;
  .saleroundinput {
    padding: 0px !important;
    border: none !important;
    outline: none !important;
  }
}
.menu-my {
  padding: 0px;
  overflow: auto;
  height: 200px;
  border-radius: 0px !important;

  &::-webkit-scrollbar {
    display: none;
  }

  .addtoken {
    background: transparent;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-family: "hregular";
    line-height: normal;
    display: flex;
    padding: 14px 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}

.cryptomain {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  .cryptomainimg {
    width: 18px;
    height: 18px;
    border-radius: 40px;
    object-fit: cover;
    object-position: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .cryptoinnerimg {
      width: 100%;
      height: 100%;
      border-radius: 40px;
      object-fit: cover;
      object-position: center;
    }
  }

  .cryptopara {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-family: "hregular";
    line-height: normal;
  }
}
